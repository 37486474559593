<template>
  <div class="WithdrawCrypto_form">
    <div class="WithdrawCrypto_title">Security check</div>
    <G2FAConfirm ref="g2faConfirm" @cancel="handleCancel" @confirm="handleConfirm" />
  </div>
</template>

<script>
import G2FAConfirm from '@/components/_common/TotpComponent/G2FAConfirm.vue';

export default {
  components: {
    G2FAConfirm,
  },
  data: () => ({}),
  computed: {},
  props: {
    totpCode: {
      type: [String, Number],
    },
  },
  methods: {
    handleConfirm(totp_code) {
      this.$emit('confirmWithdrawByG2FA', totp_code);
    },
    handleCancel() {
      this.$emit('cancelWithdraw');
    },
  },
};
</script>

<style lang="scss" scoped>
@import './WithdrawCrypto/WithdrawCrypto.scss';
.WithdrawCrypto_title {
  font-weight: 500;
  font-size: 21px;
  line-height: 25px;
  text-align: left;
  text-transform: uppercase;
  color: #ffffff;
  padding-bottom: 12px;
  border-bottom: 1px solid rgba(#2af3f3, 0.1);
  margin-bottom: 12px;
}
.Withdraw_container .Confirm_root {
  margin-top: 22px;
}
</style>
