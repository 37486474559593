<template>
  <div class="WithdrawCrypto_form">
    <v-form
      ref="form"
      autocomplete="false"
    >
      <div class="WithdrawCrypto_title">Confirm withdrawal</div>
      <div
        class="WithdrawCrypto_row"
        v-if="networkExists"
      >
        <TextFieldInput
          :value="withdrawalPaymentType"
          disabled
          name="payment_type"
          label="Network"
          hideDetails
        />
      </div>
      <div class="WithdrawCrypto_row">
        <TextFieldInput
          :value="withdrawalAddressValue"
          disabled
          name="wallet_to"
          label="Address"
          hideDetails
        />
      </div>
      <div class="WithdrawCrypto_row">
        <TextFieldInput
          :value="withdrawalAmountValue"
          disabled
          name="wallet_to"
          label="YOU GET"
          hideDetails
        />
      </div>
      <div class="WithdrawCrypto_row-btns">
        <div class="WithdrawCrypto_row-btn">
          <OutlineButton
            class="outline-button-default outline-button-full-width"
            text="Confirm"
            :disabled="buttonDisableConfirm"
            fullWidth
            @click="handleSubmitWithdraw"
          />
        </div>
        <Button
          text="cancel"
          size="large"
          @click="handleCancelExchange"
        />
      </div>
    </v-form>
  </div>
</template>

<script>
import { mapState } from "vuex";
import TextFieldInput from "@/components/_common/FormElements/TextFieldInput/TextFieldInput";
import OutlineButton from "@/components/_common/FormElements/OutlineButton/OutlineButton";
import Button from "@/components/_common/FormElements/Button/Button";
import { isCrypto } from "@/store/balance/_helpers/currencyTypeHelper";

export default {
  props: {
    currency: {
      type: String,
      required: true,
      default: "BTC"
    },
    networkExists: {
      type: Boolean,
      default: false
    },
    buttonDisableConfirm: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    OutlineButton,
    TextFieldInput,
    Button
  },
  computed: {
    ...mapState( "depositAndWithdrawal", [ "withdrawCryptoForm", "withdrawFiatGatewayForm" ] ),
    withdrawalData() {
      if ( isCrypto( this.currency ) ) {
        return this.withdrawCryptoForm;
      }
      return this.withdrawFiatGatewayForm;
    },
    withdrawalAddressValue() {
      return this.withdrawalData.wallet_to;
    },
    withdrawalAmountValue() {
      return this.withdrawalData.amount;
    },
    withdrawalPaymentType() {
      return this.withdrawalData.payment_method;
    }
  },
  methods: {
    handleSubmitWithdraw() {
      this.$emit( "confirmWithdraw" );
    },
    handleCancelExchange() {
      this.$emit( "cancelWithdraw" );
    }
  }
};
</script>

<style lang="scss"
       scoped>
@import "./WithdrawCrypto/WithdrawCrypto.scss";

.WithdrawCrypto_row-btns {
  display: flex;
  justify-content: space-between;
  align-items: center;

  button,
  .WithdrawCrypto_row-btn {
    flex: 0 1 48%;
    position: relative;
  }
}

.WithdrawCrypto_title {
  font-weight: 500;
  font-size: 21px;
  line-height: 25px;
  text-align: left;
  text-transform: uppercase;
  color: #ffffff;
  padding-bottom: 12px;
  border-bottom: 1px solid rgba(#2af3f3, 0.1);
  margin-bottom: 12px;
}
</style>
