<template>
  <div class='WalletMethod_root'>
    <div class='WalletMethod_wrapper'>
      <div class='WalletMethod_container'>
        <div
          v-if='checkDisableDescriptionWithdrawal === "NOT_VERIFIED"'
          class='WalletMethod_verification'
        >
          <PassVerification operation='withdrawal' />
        </div>
        <WithdrawBlocked v-else-if='checkDisableDescriptionWithdrawal === "BLOCKED"' />
        <div
          v-else
          class='WalletMethod_form'
        >
          <v-form
            ref='form'
            autocomplete='false'
            v-if='isConfirmationStepsActive'
          >
            <div class='WalletMethod_title'>Internal transfer</div>
            <div class='WalletMethod_separator !mb-[20px]'></div>
            <div class='WalletMethod_row'>
              <TextFieldInput
                :value='internalMovementForm.destination_account_email'
                name='destination_account_email'
                label='to'
                type="email"
                hideDetails
                :rules='[...inputRules.required, ...inputRules.email]'
                @input="handleInput(arguments[0], 'destination_account_email')"
              />
            </div>
            <div class='WalletMethod_row'
                 :class="{
              '!mb-[20px]': !showComment,
            }"
            >
              <TextFieldInput
                autocomplete="off"
                :value='internalMovementForm.amount'
                name='amount'
                label='amount'
                hideDetails
                :rules='[...inputRules.required]'
                @input="handleInput(arguments[0], 'amount')"
              />
              <div
                class='WalletMethod_TextBtn_root'
                @click='showComment = !showComment'
              >
                <iconComment class='TextBtn_icon' />
                Add comments
              </div>
            </div>
            <transition
              name='fade'
              mode='out-in'
            >
              <div
                class='WalletMethod_row !mb-[20px]'
                v-if='showComment'
              >
                <TextArea
                  :value='internalMovementForm.comment'
                  name='comment'
                  label='Comment'
                  hideDetails
                  @input="handleInput(arguments[0], 'comment')"
                />
              </div>
            </transition>
            <OutlineButton
              class="WalletMethod_copyBtn"
              text="Withdraw"
              currencyType
              :currency="currency"
              fullWidth
              @click="handleSubmitWithdraw"
            />
          </v-form>
          <InternalMovementConfirmWithdraw
            v-if='confirmStep'
            :currency='currency'
            @cancelWithdraw='handleCancelConfirm'
            @confirmWithdraw='handleWithdrawConfirmed'
            :buttonDisableConfirm="buttonDisableConfirm"
          />
          <EmailConfirmWithdraw
            v-if="emailConfirmStep"
            v-model="emailCode"
            @cancelWithdraw="handleCancelEmailConfirm"
            @confirmWithdrawByEmail="handleWithdrawConfirmedByEmail"
            :buttonDisableConfirm="buttonDisableConfirm"
          />
          <G2FAConfirmWithdraw
            ref="g2fa"
            v-if="g2faConfirmStep"
            @cancelWithdraw="handleCancelG2FAConfirm"
            @confirmWithdrawByG2FA="handleWithdrawConfirmedByG2FA"
          />
        </div>
        <!-- <div class='WalletMethod_faq mt-[20px] md:mt-[45px] 2xl:mt-[85px]'> -->
        <div class='WalletMethod_faq'>
          <LimitFeePanel
            operation='withdrawal'
            :limits='prepareLimitsInfo'
            :fees='prepareFeeInfo'
            :currency='currency'
            :method='methodName'
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import inputRules from '@/utils/inputRules'
import TextFieldInput from '@/components/_common/FormElements/TextFieldInput/TextFieldInput'
import TextArea from '@/components/_common/FormElements/TextArea/TextArea'
import OutlineButton from '@/components/_common/FormElements/OutlineButton/OutlineButton'
import LimitFeePanel from '@/components/_common/LimitFeePanel/LimitFeePanel'
import PassVerification from '@/components/_common/PassVerification/PassVerification'
import calculateOperationFee from '@/components/_common/Wallet/_helpers/calculateOperationFee'
import InternalMovementConfirmWithdraw from './InternalMovementConfirmWithdraw'
import EmailConfirmWithdraw from './EmailConfirmWithdraw'
import { getLimitsByCurrency } from '@/store/exchange/_helpers/exchangeLimits'
import { validateForm } from '@/mixins/validateForm'
import { walletDepositAndWithdraw } from '@/mixins/walletDepositAndWithdraw'
import { iconComment } from '@/assets/icons/common'
import WithdrawBlocked from '@/components/_common/WithdrawBlocked'
import G2FAConfirmWithdraw from "./G2FAConfirmWithdraw"
import { showAppNotification } from "@/utils/appNotification"

export default {
  props: {
    currency: {
      type: String,
      required: true,
      default: 'EUR',
    },
    methodName: {
      type: String,
      default: '',
    },
  },
  mixins: [ validateForm, walletDepositAndWithdraw ],
  components: {
    WithdrawBlocked,
    OutlineButton,
    LimitFeePanel,
    TextFieldInput,
    TextArea,
    PassVerification,
    InternalMovementConfirmWithdraw,
    EmailConfirmWithdraw,
    iconComment,
    G2FAConfirmWithdraw,
  },
  data: function () {
    return {
      inputRules,
      showComment: false,
      confirmStep: false,
      emailConfirmStep: false,
      g2faConfirmStep: false,
      emailCode: null,
      totpCode: null,
      buttonDisableConfirm: false,
    }
  },
  computed: {
    ...mapState( 'depositAndWithdrawal', [ 'internalMovementForm' ] ),
    ...mapGetters( 'user', [ 
      'isVerified', 
      'getStatusDisableDescriptionInternalMovement',
    ] ),
    ...mapState( 'user', [ 
      'userApiSettings', 
      'currenciesVerifyWithdrawList',
      // 'userTotpEnabled',
    ] ),
    userTotpEnabled() {
      return false;
    },
    checkDisableDescriptionWithdrawal() {
      return this.getStatusDisableDescriptionInternalMovement( this.currency )
    },
    isConfirmationStepsActive() {
      return !this.confirmStep && !this.emailConfirmStep && !this.g2faConfirmStep;
    },
    isEmailConfirmationRequired() {
      // TODO: need fix after change api
      return false;
      const { IS_WITHDRAWAL_EMAIL_VERIFICATION_ENABLED } = this.userApiSettings
      const currencyNeedsToBeVerified = this.currenciesVerifyWithdrawList.includes( this.currency )
      return IS_WITHDRAWAL_EMAIL_VERIFICATION_ENABLED && currencyNeedsToBeVerified
    },
    prepareLimitsInfo() {
      const limitsObject = getLimitsByCurrency( {
        currency: this.currency,
        type: 'CROSS_ACCOUNT',
        operation: 'internalMovement',
      } )
      return limitsObject
    },
    prepareFeeInfo() {
      const feeObject = calculateOperationFee( {
        currency: this.currency,
        type: 'CROSS_ACCOUNT',
        operation: 'internalMovement',
      } )
      return feeObject
    },
  },
  methods: {
    ...mapActions('depositAndWithdrawal', [
      'updateInternalMovementForm', 
      'apiInternalMovement', 
      'resetDepositAndWithdrawalModule',
      'apiVerifyInternalMovementByEmail',
    ]),
    handleInput(value, field) {
      this.updateInternalMovementForm({ value, field });
    },
    handleSubmitWithdraw() {
      this.updateInternalMovementForm( {
        field: 'currency',
        value: this.currency,
      } )
      this.validateForm().then( () => {
        this.confirmStep = true
      } )
    },
    handleWithdrawConfirmed() {
      if (this.userTotpEnabled && !this.isEmailConfirmationRequired) {
        this.g2faConfirmStep = true;
        this.confirmStep = false;
      } else {
        this.buttonDisableConfirm = true;
        this.apiInternalMovement().then(() => {
          if (this.isEmailConfirmationRequired) {
            this.emailConfirmStep = true;
          } else {
            showAppNotification('success', 'Internal Movement successfully created!');
          }
          this.confirmStep = false;
          this.buttonDisableConfirm = false;
        }).catch( () => {
          setTimeout( () => {
            this.buttonDisableConfirm = false;
          }, 2000 );
        } );
      };
    },    
    handleCancelConfirm() {
      this.confirmStep = false
    },
    handleCancelEmailConfirm() {
      this.resetDepositAndWithdrawalModule();
      this.emailConfirmStep = false;
      this.confirmStep = false;
    },
    handleCancelG2FAConfirm() {
      this.resetDepositAndWithdrawalModule();
      this.g2faConfirmStep = false;
      this.emailConfirmStep = false;
      this.confirmStep = false;
    },
    handleWithdrawConfirmedByEmail() {
      this.emailConfirmStep = false;
      if (this.userTotpEnabled) {
        this.g2faConfirmStep = true;
      } else {
        this.apiVerifyCodes();
      }
    },
    handleWithdrawConfirmedByG2FA(totpCode) {
      this.totpCode = totpCode;
      if (this.isEmailConfirmationRequired) {
        this.apiVerifyCodes();
      } else {
        this.updateInternalMovementForm({ field: 'totp_code', value: totpCode });
        this.totpCode = null;
        this.$refs.g2fa.$refs.g2faConfirm.clearAuthCode()
        this.apiInternalMovement().then(() => {
          this.resetDepositAndWithdrawalModule();
          this.g2faConfirmStep = false;
          showAppNotification('success', 'Internal Movement successfully created!');
        }).catch((error)=>{
        });
      }
    },
    apiVerifyCodes() {
      const request = {};
      if (this.emailCode) request.email_code = this.emailCode;
      if (this.totpCode) request.totp_code = this.totpCode;
      this.buttonDisableConfirm = true;
      this.apiVerifyInternalMovementByEmail(request).then(() => {
        this.resetDepositAndWithdrawalModule();
        this.g2faConfirmStep = false;
        this.emailConfirmStep = false;
        this.emailCode = null;
        this.totpCode = null;
        showAppNotification('success', 'Internal Movement successfully created!');
        this.buttonDisableConfirm = false;
      }).catch((error)=>{
        this.$refs.g2fa.$refs.g2faConfirm.clearAuthCode()
        setTimeout( () => {
          this.buttonDisableConfirm = false;
        }, 2000 );
      });
    },
  },
}
</script>
