<template>
  <section class="bg-white dark:bg-primaryAppSectionBg">
    <div class="p-[10px] md:py-[20px] md:px-[30px]">
      <RoundButton
        class="Withdraw_backBtn"
        withIcon
        iconType="arrowLeft"
        text="back to wallet"
        @click="goBack"
      />
      <div class="text-[21px] leading-[25px] text-white text-left pb-[20px] border-b-[1px] border-blue-500/[0.21]">
        Withdraw
        <span class="text-[#9395ab]">
          ({{ currencyToWithdraw }})
          <SquareCoinIcon
            class="align-bottom"
            :currency="currencyToWithdraw"
          />
        </span>
      </div>
      <div class="my-[24px] mx-auto max-w-full w-[115px] sm:w-[385px] md:w-[700px]">
        <swiper
          class='WalletMethod_cardsCarousel'
          ref='cardsSwiper'
          :options='swiperOptions'
          @slideChangeTransitionEnd="currentMethodSlide"
        >
          <swiper-slide
            v-for="(method, index) in availableMethods"
            :key="index"
          >
            <CurrencyCard
              :method="method"
              :currencyType="currencyType"
              :currency="currencyToWithdraw"
              :activeMethod="activeMethod"
              @clickMethod="currencyCardClickHandler"
            />
          </swiper-slide>
          <div
            class='pagination'
            slot='pagination'
          ></div>
        </swiper>
      </div>
      <div class="min-h-[400px] relative" v-if="_userModuleLoaded">
        <div class="mb-[15px] mb-[50px]">
          <div class="text-[18px] text-blue-700 dark:text-[#9395ab] text-center">Available funds</div>
          <div class="text-[24px] text-center text-blue-300 dark:text-blue-500">{{ availableBalanceByCurrency }} {{ currencyToWithdraw }}</div>
        </div>
        <div class="min-h-[400px] relative">
          <WithdrawSwiftSepa
            v-if="isSepaActive && isBusiness"
            :methodName="activeMethod"
            :currency="currencyToWithdraw"
          />
          <WithdrawSepaVerified
            v-if="isSepaActive && isVerified"
            :methodName="activeMethod"
            :currency="currencyToWithdraw"
          ></WithdrawSepaVerified>
          <WithdrawSwiftSepa
            v-if="isSwiftActive"
            :methodName="activeMethod"
            :currency="currencyToWithdraw"
          />
          <InternalMovement
            v-if="isInternalMovementActive"
            :methodName="activeMethod"
            :currency="currencyToWithdraw"
          />
          <CashboxBreinrock 
            v-if="activeMethod === 'CASHBOX_BREINROCK'"
            action="withdrawal"
            :currency="currencyToWithdraw"
          />
          <WithdrawCrypto
            v-if="isCrypto && activeMethod === 'GATEWAY'"
            :methodName="activeMethod"
            :currency="currencyToWithdraw"
          />
          <WithdrawGateway
            v-if="!isCrypto && activeMethod === 'GATEWAY'"
            :methodName="activeMethod"
            :currency="currencyToWithdraw"
          />
        </div>
      </div>
      <div class="min-h-[400px] relative" v-else>
        <Loader />
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex';
import SquareCoinIcon from '@/components/_common/SquareCoinIcon/SquareCoinIcon';
import CurrencyCard from '@/components/_common/CurrencyCard/CurrencyCard';
import { getBalanceByCurrency } from '@/components/_common/Wallet/_helpers/userBalances';
import RoundButton from '@/components/_common/FormElements/RoundButton/RoundButton';
import { allActiveWithdrawalMethods } from '@/views/Application/Account/Exchange/Wallet/_helpers/withdrawalRules';
import { allActiveInternalMovementMethods } from '@/views/Application/Account/Exchange/Wallet/_helpers/internalMovementRules';
import Loader from '@/components/_common/Loader/Loader';
import CashboxBreinrock from '../Deposit/CashboxBreinrock/CashboxBreinrock';
import WithdrawCrypto from './WithdrawCrypto/WithdrawCrypto';
import WithdrawGateway from './WithdrawGateway/WithdrawGateway';
import { roundingCurrency } from '@/utils/helpers';
import WithdrawSwiftSepa from './WithdrawSwiftSepa/WithdrawSwiftSepa';
import InternalMovement from './InternalMovement.vue';
import WithdrawSepaVerified from "./WithdrawSepaVerified/WithdrawSepaVerified";

export default {
  components: {
    SquareCoinIcon,
    RoundButton,
    CurrencyCard,
    WithdrawCrypto,
    WithdrawGateway,
    Loader,
    WithdrawSwiftSepa,
    InternalMovement,
    CashboxBreinrock,
    WithdrawSepaVerified
  },
  data: function () {
    return {
      currencyToWithdraw: '',
      activeMethod: 'GATEWAY',
      swiperOptions: {
        watchOverflow: true,
        centerInsufficientSlides: true,
        spaceBetween: 20,
        pagination: {
          el: '.pagination',
          clickable: true,
        },
        breakpoints: {
          768: {
            slidesPerView: 4,
          },
          480: {
            slidesPerView: 3,
          },
          320: {
            slidesPerView: 1,
          },
        },
      },
    }
  },
  computed: {
    ...mapGetters( 'balance', [ 'userBalances' ] ),
    ...mapGetters( 'user', [ 'isVerified', 'getTypeUserInfo' ] ),
    ...mapState( 'user', [ 'userInfo', '_userModuleLoaded', '_userModulePending' ] ),
    isBusiness() {
      return this.userInfo.account_type == 'BUSINESS';
    },
    isSepaActive() {
      return this.activeMethod === 'SEPA'
    },
    isSwiftActive() {
      return this.activeMethod === 'SWIFT'
    },
    isInternalMovementActive() {
      return this.activeMethod === 'CROSS_ACCOUNT'
    },
    availableBalanceByCurrency() {
      if ( this.currencyToWithdraw ) {
        return roundingCurrency( {
          currencyAmount: getBalanceByCurrency( this.currencyToWithdraw ),
          currency: this.currencyToWithdraw,
        } )
      }
      // if (this.currencyToWithdraw) return getBalanceByCurrency(this.currencyToWithdraw);
      return 0
    },
    availableMethods() {
      return [
        ...Object.keys( allActiveWithdrawalMethods( this.currencyToWithdraw ) ),
        ...Object.keys(allActiveInternalMovementMethods(this.currencyToWithdraw)).filter(methodName=>methodName==='CROSS_ACCOUNT'),
      ]
    },
    isCrypto() {
      return this.currencyType === 'crypto'
    },
    currencyType() {
      if ( this.userBalances ) {
        const coin = this.userBalances.find( item => {
          return item.currency === this.currencyToWithdraw
        } )
        const type = ( coin && coin.currencyType ) || ''
        return type
      }
      return ''
    },
  },
  watch: {
    '$route.params.currency': {
      handler( newVal ) {
        this.currencyToWithdraw = newVal
      },
      immediate: true,
    },
    _userModuleLoaded: {
      handler( newVal ) {
        if ( newVal && !this.availableMethods.includes(this.activeMethod)) this.activeMethod = this.availableMethods[ 0 ]
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions( 'depositAndWithdrawal', [ 'resetDepositAndWithdrawalModule' ] ),
    goBack() {
      this.$router.go( -1 )
    },
    currentMethodSlide() {
      if (window.innerWidth < 480) {
        const index = this.$refs.cardsSwiper.$el.getElementsByClassName('swiper-slide-active')[0].getElementsByClassName('CurrencyCard_root')[0].getAttribute('data-method')
        this.currencyCardClickHandler(index)
      }
    },
    currencyCardClickHandler( method ) {
      this.activeMethod = method
    },
  },
  mounted() {
    this.resetDepositAndWithdrawalModule()
    // this.$nextTick(() => {
    //   this.activeMethod = 'GATEWAY';
    // });
  },
}
</script>

<style lang='scss'>
@import "../WalletMethod.scss";
@import "./Withdraw.scss";
</style>
