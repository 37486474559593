<template>
  <div class="WalletMethod_Confirm">
    <v-form
      ref="form"
      autocomplete="false"
    >
      <WalletMethodTitle>
        <div class="md:text-center md:mb-[10px] lg:mb-0 lg:text-left lg:text-[16px] 2xl:text-[21px]">Confirm withdrawal</div>
        <ChooseTemplate/>
      </WalletMethodTitle>
      <div class="WalletMethod_separator !mb-[20px]"></div>
      <div class="flex flex-wrap items-end text-[16px] tracking-[0.4px] leading-[18px] mb-[13px]">
        <img src="@/assets/icons/icon-beneficiary.svg" class="mr-[5px]">
        <div>Beneficiary Bank</div>
      </div>
      <div class="md:flex md:flex-wrap">
        <div class="md:max-w-[50%] md:w-full">
          <div
            v-if="withdrawForm.additional_info.bank_details.bic"
            class="flex items-center flex-wrap mb-[10px] justify-between md:justify-start"
          >
            <div class="text-[14px] leading-[16px] text-gray-600 max-w-[67px] w-full text-left mr-[24px]">Swift / bic:</div>
            <div class="text-white text-[14px] leading-[16px] font-normal text-right">{{ withdrawForm.additional_info.bank_details.bic }}</div>
          </div>
          <div
            v-if="withdrawForm.additional_info.bank_details.name"
            class="flex items-center flex-wrap mb-[10px] justify-between md:justify-start"
          >
            <div class="text-[14px] leading-[16px] text-gray-600 max-w-[67px] w-full text-left mr-[24px]">Name:</div>
            <div class="text-white text-[14px] leading-[16px] font-normal">{{ withdrawForm.additional_info.bank_details.name }}</div>
          </div>
          <div
            v-if="withdrawForm.additional_info.bank_details.address"
            class="flex items-center flex-wrap mb-[10px] justify-between md:justify-start"
          >
            <div class="text-[14px] leading-[16px] text-gray-600 max-w-[67px] w-full text-left mr-[24px]">Address:</div>
            <div class="text-white text-[14px] leading-[16px] font-normal">{{withdrawForm.additional_info.bank_details.address }}</div>
          </div>
        </div>
        <div class="md:max-w-[50%] md:w-full">
          <div
            v-if="withdrawForm.additional_info.bank_details.location"
            class="flex items-center flex-wrap mb-[10px] justify-between md:justify-start"
          >
            <div class="text-[14px] leading-[16px] text-gray-600 max-w-[67px] w-full text-left mr-[24px]">Location:</div>
            <div class="text-white text-[14px] leading-[16px] font-normal">{{ withdrawForm.additional_info.bank_details.location }}</div>
          </div>
          <div
            v-if="withdrawForm.additional_info.bank_details.country"
            class="flex items-center flex-wrap mb-[10px] justify-between md:justify-start"
          >
            <div class="text-[14px] leading-[16px] text-gray-600 max-w-[67px] w-full text-left mr-[24px]">Country:</div>
            <div class="text-white text-[14px] leading-[16px] font-normal">{{ withdrawForm.additional_info.bank_details.country }}</div>
          </div>
          <div
            v-if="withdrawForm.additional_info.bank_details.aba.length"
            class="flex items-center flex-wrap mb-[10px] justify-between md:justify-start"
          >
            <div class="text-[14px] leading-[16px] text-gray-600 max-w-[67px] w-full text-left mr-[24px]">ABA/RTN:</div>
            <div class="text-white text-[14px] leading-[16px] font-normal">{{ withdrawForm.additional_info.bank_details.aba }}</div>
          </div>
        </div>
      </div>
      <div class="WalletMethod_separator !mb-[20px]"></div>
      <div class="flex flex-wrap items-end text-[16px] tracking-[0.4px] leading-[18px] mb-[13px]">
        <img src="@/assets/icons/icon-beneficiary-customer.svg" class="mr-[5px]">
        <div>Beneficiary customer</div>
      </div>
      <div class="md:flex md:flex-wrap">
        <div class="md:max-w-[50%] md:w-full lg:max-w-[48%] lg:w-full">
          <div
            v-if="withdrawForm.additional_info.customer_details.name"
            class="flex items-center flex-wrap mb-[10px] justify-between md:justify-start"
          >
            <div class="text-[14px] leading-[16px] text-gray-600 mr-[24px] max-w-[139px] w-full text-left">Name:</div>
            <div class="text-white text-[14px] leading-[16px] font-normal">{{ withdrawForm.additional_info.customer_details.name }}</div>
          </div>
          <div
            v-if="withdrawForm.additional_info.customer_details.address_line_1"
            class="flex items-center flex-wrap mb-[10px] justify-between md:justify-start"
          >
            <div class="text-[14px] leading-[16px] text-gray-600 mr-[24px] max-w-[139px] w-full text-left">Address line 1:</div>
            <div class="text-white text-[14px] leading-[16px] font-normal">{{ withdrawForm.additional_info.customer_details.address_line_1 }}</div>
          </div>
          <div
            v-if="withdrawForm.additional_info.customer_details.address_line_2"
            class="flex items-center flex-wrap mb-[10px] justify-between md:justify-start"
          >
            <div class="text-[14px] leading-[16px] text-gray-600 mr-[24px] max-w-[139px] w-full text-left">Address line 2:</div>
            <div class="text-white text-[14px] leading-[16px] font-normal">{{ withdrawForm.additional_info.customer_details.address_line_2 }}</div>
          </div>
          <div
            v-if="withdrawForm.additional_info.customer_details.region"
            class="flex items-center flex-wrap mb-[10px] justify-between md:justify-start"
          >
            <div class="text-[14px] leading-[16px] text-gray-600 mr-[24px] max-w-[139px] w-full text-left">State/province/region:</div>
            <div class="text-white text-[14px] leading-[16px] font-normal">{{ withdrawForm.additional_info.customer_details.region }}</div>
          </div>
          <div
            v-if="withdrawForm.additional_info.customer_details.city"
            class="flex items-center flex-wrap mb-[10px] justify-between md:justify-start"
          >
            <div class="text-[14px] leading-[16px] text-gray-600 mr-[24px] max-w-[139px] w-full text-left">City:</div>
            <div class="text-white text-[14px] leading-[16px] font-normal">{{ withdrawForm.additional_info.customer_details.city }}</div>
          </div>
        </div>
        <div class="md:max-w-[48%] md:w-full">
          <div
            v-if="withdrawForm.additional_info.customer_details.country"
            class="flex items-center flex-wrap mb-[10px] justify-between md:justify-start"
          >
            <div class="text-[14px] leading-[16px] text-gray-600 mr-[24px] max-w-[90px] w-full text-left">Country:</div>
            <div class="text-white text-[14px] leading-[16px] font-normal">{{ withdrawForm.additional_info.customer_details.country }}</div>
          </div>
          <div
            v-if="withdrawForm.additional_info.customer_details.iban"
            class="flex items-center flex-wrap mb-[10px] justify-between md:justify-start"
          >
            <div class="text-[14px] leading-[16px] text-gray-600 mr-[24px] max-w-[90px] w-full text-left">ACC#/IBAN:</div>
            <div class="text-ellipsis overflow-hidden max-w-[230px] text-white text-[14px] leading-[16px] font-normal" :title="withdrawForm.additional_info.customer_details.iban">{{ withdrawForm.additional_info.customer_details.iban }}</div>
          </div>
          <div
            v-if="withdrawForm.additional_info.customer_details.ref_message"
            class="flex items-center flex-wrap mb-[10px] justify-between md:justify-start"
          >
            <div class="text-[14px] leading-[16px] text-gray-600 mr-[24px] max-w-[90px] w-full text-left">Ref message:</div>
            <div class="text-white text-[14px] leading-[16px] font-normal">{{ withdrawForm.additional_info.customer_details.ref_message }}</div>
          </div>
          <div
            v-if="withdrawForm.additional_info.customer_details.description"
            class="flex items-center flex-wrap mb-[10px] justify-between md:justify-start"
          >
            <div class="text-[14px] leading-[16px] text-gray-600 mr-[24px] max-w-[90px] w-full text-left">Description:</div>
            <div class="text-white text-[14px] leading-[16px] font-normal">{{ withdrawForm.additional_info.customer_details.description }}</div>
          </div>
          <div
            v-if="withdrawForm.amount"
            class="flex items-center flex-wrap mb-[10px] justify-between md:justify-start"
          >
            <div class="text-[14px] leading-[16px] text-gray-600 mr-[24px] max-w-[90px] w-full text-left">You get:</div>
            <div class="text-white text-[14px] leading-[16px] font-normal">{{ withdrawForm.amount }} {{ currency }}</div>
          </div>
        </div>
      </div>
      <div class="text-left mt-[20px]">
        <Checkbox>
          <span slot="label" class="text-[12px]">Save as template</span>
        </Checkbox>
      </div>
      <div class="flex items-center flex-wrap justify-center mt-[15px]">
        <Button
          class="mb-[10px] max-w-full w-full 2xl:max-w-[260px] 2xl:w-full 2xl:mr-[10px] 2xl:mb-[0]"
          text="Previous step"
          size="large"
          @click="handleCancel"
        />
        <OutlineButton
          class="2xl:max-w-[260px]"
          text="Confirm"
          fullWidth
          @click="handleWithdrawConfirmed"
        />
      </div>
    </v-form>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import OutlineButton from '@/components/_common/FormElements/OutlineButton/OutlineButton';
import Button from '@/components/_common/FormElements/Button/Button';
import ChooseTemplate from "@/components/_common/ChooseTemplate/ChooseTemplate";
import WalletMethodTitle from "@/components/WalletMethodTitle"
import { showAppNotification } from "@/utils/appNotification";
import Checkbox from "@/components/_common/FormElements/Checkbox/Checkbox";

export default {
  name: 'WithdrawSwiftSepaStepConfirm',
  components: {
    Checkbox,
    OutlineButton,
    Button,
    ChooseTemplate,
    WalletMethodTitle
  },
  props: {
    currency: {
      type: String,
      required: true,
    },
    methodName: {
      type: String,
    },
  },
  computed: {
    ...mapState('depositAndWithdrawal', ['withdrawSwiftForm', 'withdrawSepaForm']),
    withdrawForm() {
      if (this.methodName == 'SWIFT') {
        return this.withdrawSwiftForm;
      } else if (this.methodName == 'SEPA') {
        return this.withdrawSepaForm;
      }

      return this.withdrawSepaForm;
    },
  },
  methods: {
    ...mapActions( 'depositAndWithdrawal',
      [
        'updateWithdrawSwiftForm',
        'updateWithdrawSepaForm',
        'apiWithdrawSwiftSepa'
      ] ),
    updateWithdrawForm( { value, field } ) {
      if ( this.methodName == 'SWIFT' ) {
        this.updateWithdrawSwiftForm( { value, field } )
      } else if ( this.methodName == 'SEPA' ) {
        this.updateWithdrawSepaForm( { value, field } )
      }
    },
    // handleSubmitWithdraw() {
    //   this.$emit('confirmWithdraw');
    // },
    // handleCancelExchange() {
    //   this.$emit('cancelWithdraw');
    // },
    handleCancel() {
      this.updateWithdrawForm({ value: 2, field: 'step' })
    },
    handleWithdrawConfirmed() {
      this.updateWithdrawForm( {
        field: 'currency',
        value: this.currency,
      } )
      this.apiWithdrawSwiftSepa( this.methodName ).then( () => {
        showAppNotification('success', 'Withdrawal successfully created!');
      } )
    },
  },
};
</script>
