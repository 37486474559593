<template>
  <div class='WalletMethod_root'>
    <div class='WalletMethod_wrapper'>
      <div class='md:flex md:justify-between md:items-start'>
        <div
          v-if='checkDisableDescriptionWithdrawal === "NOT_VERIFIED"'
          class='grow'
        >
          <PassVerification operation='withdrawal' />
        </div>
        <WithdrawBlocked v-else-if='checkDisableDescriptionWithdrawal === "BLOCKED"' />
        <div
          v-else
          class='grow rounded-[12px] bg-blue-800/[0.44] shadow-component-wrapper-root py-[15px] px-[15px] md:py-[30px] md:px-[40px]'
        >
          <v-form
            ref='form'
            autocomplete='false'
          >
            <WalletMethodTitle>
              <div class="md:text-center md:mb-[10px] lg:mb-0 lg:text-left lg:text-[16px] 2xl:text-[21px]">beneficiary customer</div>
              <ChooseTemplate/>
            </WalletMethodTitle>
            <WalletMethodSeparator class="!mb-[20px]" />
            <WalletMethodRow class="input-row">
              <TextFieldInput
                :value="withdrawForm.additional_info.customer_details.name"
                name="customer_name"
                label="name *"
                hideDetails="auto"
                :rules='[...inputRules.required, ...inputRules.getCheckOnlyLetters]'
                @input="handleInput(arguments[0], 'additional_info.customer_details.name')"
                :maxLength="150"
              />
              <TextFieldInput
                :value='withdrawForm.additional_info.customer_details.address_line_1'
                name='customer_address_line_1'
                label='address line 1 *'
                hideDetails='auto'
                :rules="[...inputRules.required, ...inputRules.getCheckSpecialSymbol]"
                @input="handleInput(arguments[0], 'additional_info.customer_details.address_line_1')"
                :maxLength='150'
              />
            </WalletMethodRow>
            <WalletMethodRow class="input-row">
              <TextFieldInput
                :value='withdrawForm.additional_info.customer_details.address_line_2'
                name='customer_address_line_2'
                label='address line 2'
                hideDetails='auto'
                @input="handleInput(arguments[0], 'additional_info.customer_details.address_line_2')"
              />
              <TextFieldInput
                :value="withdrawForm.additional_info.customer_details.region"
                name='customer_region'
                label='state/province/region *'
                hideDetails='auto'
                :rules="[...inputRules.required, ...inputRules.getCheckOnlyLetters]"
                @input="handleInput(arguments[0], 'additional_info.customer_details.region')"
                :maxLength='150'
              />
            </WalletMethodRow>
            <WalletMethodRow class="input-row">
              <TextFieldInput
                :value='withdrawForm.additional_info.customer_details.city'
                name='customer_city'
                label='city *'
                hideDetails='auto'
                :rules='[...inputRules.required, ...inputRules.getCheckOnlyLetters]'
                @input="handleInput(arguments[0], 'additional_info.customer_details.city')"
                :maxLength='150'
              />
              <Select
                :items='countries'
                :value='withdrawForm.additional_info.customer_details.country'
                name='customer_country'
                label='country *'
                hideDetails='auto'
                :rules='[...inputRules.required, ...inputRules.getCheckSpecialSymbol]'
                @change="handleInput(arguments[0].value, 'additional_info.customer_details.country')"
                :maxLength='150'
                componentName="VAutocomplete"
              />
            </WalletMethodRow>
            <WalletMethodRow class="input-row">
              <TextFieldInput
                :value='withdrawForm.additional_info.customer_details.iban'
                name='customer_iban'
                label='acc#/iban *'
                hideDetails='auto'
                :rules='[...inputRules.required, ...inputRules.ibanRules]'
                @input="handleInput(arguments[0], 'additional_info.customer_details.iban')"
                :maxLength='34'
                removeSpacesHandler
              />
              <TextFieldInput
                :value='withdrawForm.additional_info.customer_details.ref_message'
                name='ref_message'
                label='ref message *'
                hideDetails='auto'
                :rules='[...inputRules.required, ...inputRules.getCheckSpecialSymbol,]'
                @input="handleInput(arguments[0], 'additional_info.customer_details.ref_message')"
                :maxLength='150'
              />
            </WalletMethodRow>
            <WalletMethodRow class="input-row">
              <TextArea
                customClass="custom-text-area"
                :value='withdrawForm.additional_info.customer_details.description'
                name='desc'
                label='description'
                rows='1'
                hideDetails='auto'
                @input="handleInput(arguments[0], 'additional_info.customer_details.description')"
              />
              <Input
                name='amount'
                label='You Get *'
                :value='withdrawForm.amount'
                btnText='Withdraw all'
                rightLabelString='FEE'
                :rightLabelValue="computedFee"
                :rules='[...inputRules.required, ...inputRules.getCheckSpecialSymbol]'
                type='number'
                @input="handleInput(arguments[0], 'amount')"
                @clickBtn='handleClickInputBtn'
                :maxLength='150'
                defaultBtn
              />

            </WalletMethodRow>
            <div class="flex flex-wrap items-center justify-center">
              <Button
                class="mb-[10px] max-w-full w-full 2xl:max-w-[260px] 2xl:w-full 2xl:mr-[10px] 2xl:mb-[0]"
                text="Previous step"
                size="large"
                @click="handleCancel"
              />
              <OutlineButton
                class="2xl:max-w-[260px]"
                text="Next step"
                fullWidth
                currencyType
                :currency="currency"
                @click="handleSubmitWithdraw"
              />
            </div>
          </v-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import inputRules from '@/utils/inputRules'
import TextFieldInput from '@/components/_common/FormElements/TextFieldInput/TextFieldInput'
import TextArea from '@/components/_common/FormElements/TextArea/TextArea'
import OutlineButton from '@/components/_common/FormElements/OutlineButton/OutlineButton'
import PassVerification from '@/components/_common/PassVerification/PassVerification'
import calculateOperationFee from '@/components/_common/Wallet/_helpers/calculateOperationFee'
import withdrawAll from '@/components/_common/Wallet/_helpers/withdrawAll'
import { validateForm } from '@/mixins/validateForm'
import Input from '@/components/_common/FormElements/Input/Input'
import { walletDepositAndWithdraw } from '@/mixins/walletDepositAndWithdraw'
import Countries from '@/utils/countries.js'
import Select from '@/components/_common/FormElements/Select/Select'
import WithdrawBlocked from '@/components/_common/WithdrawBlocked'
import WalletMethodRow from "@/components/WalletMethodRow"
import WalletMethodTitle from "@/components/WalletMethodTitle"
import WalletMethodSeparator from "@/components/WalletMethodSeparator"
import ChooseTemplate from "@/components/_common/ChooseTemplate/ChooseTemplate";
import Button from '@/components/_common/FormElements/Button/Button';

export default {
  name: "WithdrawSwiftSepaStepTwo",
  props: {
    currency: {
      type: String,
      required: true
    },
    methodName: {
      type: String
    }
  },
  mixins: [ validateForm, walletDepositAndWithdraw ],
  components: {
    Button,
    ChooseTemplate,
    WithdrawBlocked,
    WalletMethodSeparator,
    WalletMethodTitle,
    WalletMethodRow,
    OutlineButton,
    TextFieldInput,
    TextArea,
    PassVerification,
    Input,
    Select
  },
  data() {
    return {
      inputRules,
    }
  },
  computed: {
    ...mapState( 'depositAndWithdrawal', [ 'withdrawSwiftForm', 'withdrawSepaForm' ] ),
    ...mapGetters( 'user', [ 'isVerified', 'getStatusDisableDescriptionWithdrawal' ] ),
    ...mapState( 'user', [ 'userApiSettings', 'currenciesVerifyWithdrawList' ] ),
    checkDisableDescriptionWithdrawal() {
      return this.getStatusDisableDescriptionWithdrawal( this.currency, this.methodName )
    },
    withdrawForm() {
      if ( this.methodName == 'SWIFT' ) {
        return this.withdrawSwiftForm
      } else if ( this.methodName == 'SEPA' ) {
        return this.withdrawSepaForm
      }
      return this.withdrawSepaForm
    },
    countries() {
      return Countries.map( item => item.country )
    },
    computedFee() {
      const feeObject = calculateOperationFee( {
        currency: this.currency,
        type: this.methodName,
        operation: 'withdrawal',
      } )
      return this.computedFeeHelper( feeObject )
    },
  },
  methods: {
    ...mapActions( 'depositAndWithdrawal',
      [
        'updateWithdrawSwiftForm',
        'updateWithdrawSepaForm',
        'apiWithdrawSwiftSepa'
      ] ),
    updateWithdrawForm( { value, field } ) {
      if ( this.methodName == 'SWIFT' ) {
        this.updateWithdrawSwiftForm( { value, field } )
      } else if ( this.methodName == 'SEPA' ) {
        this.updateWithdrawSepaForm( { value, field } )
      }
    },
    handleInput( value, field ) {
      this.updateWithdrawForm( { value, field } )
    },
    handleSubmitWithdraw() {
      this.validateForm().then( () => {
        this.updateWithdrawForm({ value: 3, field: 'step' })
      } )
    },
    handleCancel() {
      this.updateWithdrawForm({ value: 1, field: 'step' })
    },
    handleClickInputBtn() {
      const availableAmountToWithdraw = withdrawAll( {
        currency: this.currency,
        type: this.methodName,
      } )
      this.handleInput( availableAmountToWithdraw, 'amount' )
    },
  },
}
</script>
<style scoped lang="scss">
.input-row {
  display: grid;
  grid-gap: 15px;
  margin: 0 0 15px;

  @media only screen and (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
  }
}
</style>