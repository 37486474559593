<template>
  <div class='WalletMethod_root'>
    <div class='WalletMethod_wrapper'>
      <div class='lg:flex lg:justify-between lg:items-start'>
        <div
          v-if='checkDisableDescriptionWithdrawal === "NOT_VERIFIED"'
          class='grow m-[0_0_20px] md:m-[0_30px_0_0]'
        >
          <PassVerification operation='withdrawal' />
        </div>
        <WithdrawBlocked v-else-if='checkDisableDescriptionWithdrawal === "BLOCKED"' />
        <div
          v-else
          class='grow m-[0_0_20px] md:m-[0_30px_0_0]'
        >
          <StepsBar
            :stepsBar="stepsBar"
            :activeStep="methodName === 'SEPA' ? withdrawSepaForm.step : methodName === 'SWIFT' ? withdrawSwiftForm.step : false"
          ></StepsBar>
          <component
            :is="currentStepComponent"
            :methodName="methodName"
            :currency="currency"
          ></component>
        </div>
        <!-- <div class="shirk-0 md:basis-[390px] mt-[20px] md:mt-[45px] 2xl:mt-[85px]"> -->
        <div class="shirk-0 md:basis-[390px]">
          <LimitFeePanel
            operation='withdrawal'
            :limits='prepareLimitsInfo'
            :fees='prepareFeeInfo'
            :currency='currency'
            :method='methodName'
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StepsBar from "@/components/_common/StepsBar";
import { mapState, mapGetters, mapActions } from 'vuex'
import LimitFeePanel from '@/components/_common/LimitFeePanel/LimitFeePanel'
import PassVerification from '@/components/_common/PassVerification/PassVerification'
import calculateOperationFee from '@/components/_common/Wallet/_helpers/calculateOperationFee'
import { getLimitsByCurrency } from '@/store/exchange/_helpers/exchangeLimits'
import { validateForm } from '@/mixins/validateForm'
import Input from '@/components/_common/FormElements/Input/Input'
import { walletDepositAndWithdraw } from '@/mixins/walletDepositAndWithdraw'
import Select from '@/components/_common/FormElements/Select/Select'
import { showAppNotification } from '@/utils/appNotification'
import WithdrawBlocked from '@/components/_common/WithdrawBlocked'
import WalletMethodTitle from "@/components/WalletMethodTitle"
import WalletMethodSeparator from "@/components/WalletMethodSeparator"
import ChooseTemplate from "@/components/_common/ChooseTemplate/ChooseTemplate";
import WithdrawSwiftSepaStepOne from "@/views/Application/Account/Exchange/Wallet/DepositAndWithdraw/Withdraw/WithdrawSwiftSepa/WithdrawSwiftSepaStepOne";
import WithdrawSwiftSepaStepTwo from "@/views/Application/Account/Exchange/Wallet/DepositAndWithdraw/Withdraw/WithdrawSwiftSepa/WithdrawSwiftSepaStepTwo";
import WithdrawSwiftSepaStepConfirm from "@/views/Application/Account/Exchange/Wallet/DepositAndWithdraw/Withdraw/WithdrawSwiftSepa/WithdrawSwiftSepaStepConfirm";
export default {
  props: {
    currency: {
      type: String,
      required: true
    },
    methodName: {
      type: String
    }
  },
  mixins: [ validateForm, walletDepositAndWithdraw ],
  components: {
    StepsBar,
    WithdrawSwiftSepaStepOne,
    WithdrawSwiftSepaStepTwo,
    WithdrawSwiftSepaStepConfirm,
    ChooseTemplate,
    WithdrawBlocked,
    WalletMethodSeparator,
    WalletMethodTitle,
    LimitFeePanel,
    PassVerification,
    Input,
    Select
  },
  data() {
    return {
      confirmStep: false,
      stepsBar: [
        {
          stepNumber: 1,
          stepName: 'Beneficiary bank',
          iconUrl: 'icon-beneficiary-step.svg'
        },
        {
          stepNumber: 2,
          stepName: 'Beneficiary customer',
          iconUrl: 'icon-beneficiary-customer-step.svg'
        },
        {
          stepNumber: 3,
          stepName: 'Confirm',
          iconUrl: 'icon-confirm-step.svg'
        }
      ]
    }
  },
  computed: {
    ...mapState( 'depositAndWithdrawal', [ 'withdrawSwiftForm', 'withdrawSepaForm' ] ),
    ...mapGetters( 'user', [ 'isVerified', 'getStatusDisableDescriptionWithdrawal' ] ),
    ...mapState( 'user', [ 'userApiSettings', 'currenciesVerifyWithdrawList' ] ),
    currentStepComponent() {
      const step = this.methodName === 'SEPA' ? this.withdrawSepaForm.step : this.methodName === 'SWIFT' ? this.withdrawSwiftForm.step : false
      switch (step) {
        case 1:
          return 'WithdrawSwiftSepaStepOne';
        case 2:
          return 'WithdrawSwiftSepaStepTwo';
        case 3:
          return 'WithdrawSwiftSepaStepConfirm';
        default:
          return 1;
      }
    },
    checkDisableDescriptionWithdrawal() {
      return this.getStatusDisableDescriptionWithdrawal( this.currency, this.methodName )
    },
    prepareLimitsInfo() {
      const limitsObject = getLimitsByCurrency( {
        currency: this.currency,
        type: this.methodName,
        operation: "withdrawal",
      } )
      return limitsObject
    },
    prepareFeeInfo() {
      const feeObject = calculateOperationFee( {
        currency: this.currency,
        type: this.methodName,
        operation: "withdrawal",
      } )
      return feeObject
    },
  },
  methods: {
    ...mapActions( 'depositAndWithdrawal', [ 'updateWithdrawSwiftForm', 'updateWithdrawSepaForm', 'apiWithdrawSwiftSepa', 'resetDepositAndWithdrawalModule' ] ),
    updateWithdrawForm( { value, field } ) {
      if ( this.methodName == 'SWIFT' ) {
        this.updateWithdrawSwiftForm( { value, field } )
      } else if ( this.methodName == 'SEPA' ) {
        this.updateWithdrawSepaForm( { value, field } )
      }
    },
    handleSubmitWithdraw() {
      this.validateForm().then( () => {
        if ( this.withdrawForm.amount ) this.confirmStep = true
      } )
    },
    handleWithdrawConfirmed() {
      this.updateWithdrawForm( {
        field: 'currency',
        value: this.currency,
      } )
      this.apiWithdrawSwiftSepa( this.methodName ).then( () => {
        showAppNotification('success', 'Withdrawal successfully created!');
      } )
    },
  },
}
</script>
