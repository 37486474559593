import store from '@/store';

export const allActiveInternalMovementMethods = currency => {
  const {
    state: {
      user: { userInternalMovementRules },
    },
  } = store;
  if (userInternalMovementRules && currency) {
    const methodsByCurrency = userInternalMovementRules[currency];
    if (!methodsByCurrency) return {};
    const availableMethodsByCurrency = Object.keys(methodsByCurrency).reduce((acc, type) => {
      const method = methodsByCurrency[type];
      if (method?.disable_description !== 'DISABLED') {
        acc[type] = method;
      }
      return acc;
    }, {});
    return availableMethodsByCurrency;
  }
  return {};
};
