<template>
  <div class='WithdrawCrypto_root'>
    <div class='WithdrawCrypto_wrapper'>
      <div class='WithdrawCrypto_container'>
        <div
          v-if='checkDisableDescriptionWithdrawal === "NOT_VERIFIED"'
          class='WalletMethod_verification'
        >
          <PassVerification operation='withdrawal' />
        </div>
        <WithdrawBlocked v-else-if='checkDisableDescriptionWithdrawal === "BLOCKED"' />
        <template v-else>
          <div
            class="WithdrawCrypto_form"
            v-if="isConfirmationStepsActive && isMethodAvailable"
          >
            <v-form
              ref="form"
              autocomplete="false"
            >
              <div class="WithdrawCrypto_row">
                <TextFieldInput
                  :value="withdrawFiatGatewayForm.wallet_to"
                  name="wallet_to"
                  label="Card number"
                  mask="#### #### #### #### ####"
                  hideDetails
                  :rules="[...inputRules.required]"
                  @input="handleInput(arguments[0], 'wallet_to')"
                />
              </div>
              <div class="WithdrawCrypto_row">
                <Input
                  name="amount"
                  label="You Get"
                  :value="withdrawFiatGatewayForm.amount"
                  btnText="Withdraw all"
                  rightLabelString="FEE"
                  :rightLabelFee="calculateFeeToShow"
                  :rightLabelValue="computedFee"
                  :rules="[...inputRules.required]"
                  type="number"
                  @input="handleInput(arguments[0], 'amount')"
                  @clickBtn="handleClickInputBtn"
                  defaultBtn
                />
                <div class="TextBtn_root"
                    @click="showComment = !showComment">
                  <SvgIcon
                    class="TextBtn_icon"
                    icon="iconComment"
                  ></SvgIcon>
                  Add comments
                </div>
              </div>
              <transition
                name="fade"
                mode="out-in"
              >
                <div
                  class="WithdrawCrypto_row"
                  v-if="showComment"
                >
                  <TextArea
                    :value="withdrawFiatGatewayForm.comment"
                    name="comment"
                    label="Comment"
                    hideDetails
                    @input="handleInput(arguments[0], 'comment')"
                  />
                </div>
              </transition>
              <OutlineButton
                class="outline-button-default outline-button-full-width outline-button"
                text="Withdraw"
                fullWidth
                currencyType
                :currency="currency"
                @click="handleSubmitWithdraw"/>
            </v-form>
          </div>
          <ConfirmWithdraw
            v-if="confirmStep"
            :currency="currency"
            :buttonDisableConfirm="buttonDisableConfirm"
            @cancelWithdraw="handleCancelConfirm"
            @confirmWithdraw="handleWithdrawConfirmed"
          />
          <EmailConfirmWithdraw
            v-if="emailConfirmStep"
            @cancelWithdraw="handleCancelEmailConfirm"
            @confirmWithdrawByEmail="handleWithdrawConfirmedByEmail"
          />
        </template>
        <!-- <div class="WithdrawCrypto_faq mt-[20px] md:mt-[45px] 2xl:mt-[85px]"> -->
        <div class="WithdrawCrypto_faq">
          <LimitFeePanel
            :method='methodName'
            operation='withdrawal'
            :currency='currency'
            :fees='prepareFeeInfo'
            :limits='prepareLimitsInfo'
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import inputRules from '@/utils/inputRules';
import TextFieldInput from '@/components/_common/FormElements/TextFieldInput/TextFieldInput';
import TextArea from '@/components/_common/FormElements/TextArea/TextArea';
import Input from '@/components/_common/FormElements/Input/Input';
import OutlineButton from '@/components/_common/FormElements/OutlineButton/OutlineButton';
import LimitFeePanel from '@/components/_common/LimitFeePanel/LimitFeePanel';
import withdrawAll from '@/components/_common/Wallet/_helpers/withdrawAll';
import calculateOperationFee from '@/components/_common/Wallet/_helpers/calculateOperationFee';
import PassVerification from '@/components/_common/PassVerification/PassVerification';
import { allActiveWithdrawalMethods } from '@/views/Application/Account/Exchange/Wallet/_helpers/withdrawalRules';
import { getLimitsByCurrency } from '@/store/exchange/_helpers/exchangeLimits';
import { validateForm } from '@/mixins/validateForm';
import { walletDepositAndWithdraw } from '@/mixins/walletDepositAndWithdraw';
import ConfirmWithdraw from '../ConfirmWithdraw';
import EmailConfirmWithdraw from '../EmailConfirmWithdraw';
import { calculateFeeAmount } from '@/utils/helpers';
import SvgIcon from "@/components/_common/SvgIcon";
import { showAppNotification } from '@/utils/appNotification';
import WithdrawBlocked from '@/components/_common/WithdrawBlocked';

export default {
  props: {
    currency: {
      type: String,
      required: true,
      default: 'UAH',
    },
    methodName: {
      type: String,
      default: '',
    },
  },
  mixins: [ validateForm , walletDepositAndWithdraw ],
  components: {
    WithdrawBlocked,
    OutlineButton,
    LimitFeePanel,
    TextFieldInput,
    Input,
    TextArea,
    ConfirmWithdraw,
    EmailConfirmWithdraw,
    PassVerification,
    SvgIcon,
  },
  data: () => ( {
    inputRules,
    showComment: false,
    confirmStep: false,
    emailConfirmStep: false,
    buttonDisableConfirm: false,
  } ),
  computed: {
    ...mapState( 'user', [ 'userApiSettings', 'currenciesVerifyWithdrawList' ] ),
    ...mapGetters( 'user', [ 'isVerified', 'getStatusDisableDescriptionWithdrawal' ] ),
    ...mapState( 'depositAndWithdrawal', [ 'withdrawFiatGatewayForm' ] ),
    checkDisableDescriptionWithdrawal() {
      return this.getStatusDisableDescriptionWithdrawal( this.currency, this.methodName )
    },
    isMethodAvailable() {
      if ( this.methodName ) {
        const currentMethod = allActiveWithdrawalMethods( this.currency )[ this.methodName ]
        const { disable_description } = currentMethod
        if ( disable_description === 'ACCOUNT_VERIFICATION_REQUIRED' && !this.isVerified ) {
          return false
        }
        return true
      }
      return false
    },
    isConfirmationStepsActive() {
      return !this.confirmStep && !this.emailConfirmStep
    },
    isEmailConfirmationRequired() {
      // TODO:Need to fix when the mail check will be added
      return false
      const { IS_WITHDRAWAL_EMAIL_VERIFICATION_ENABLED } = this.userApiSettings
      const currencyNeedsToBeVerified = this.currenciesVerifyWithdrawList.includes( this.currency )
      return IS_WITHDRAWAL_EMAIL_VERIFICATION_ENABLED && currencyNeedsToBeVerified
    },
    prepareLimitsInfo() {
      const limitsObject = getLimitsByCurrency( {
        currency: this.currency,
        type: 'GATEWAY',
        operation: 'withdrawal',
      } )
      return limitsObject
    },
    prepareFeeInfo() {
      const feeObject = calculateOperationFee( {
        currency: this.currency,
        type: 'GATEWAY',
        operation: 'withdrawal',
      } )
      return feeObject
    },
    // Это нужно для хелпера
    calculateFee() {
      const feeObject = calculateOperationFee( {
        currency: this.currency,
        type: 'GATEWAY',
        operation: 'withdrawal',
      } )
      return feeObject
    },
    computedFee() {
      const feeObject = calculateOperationFee( {
        currency: this.currency,
        type: 'GATEWAY',
        operation: 'withdrawal',
      } )
      return this.computedFeeHelper( feeObject )
    },
    calculateFeeToShow() {
      return calculateFeeAmount( {
        amount: this.withdrawFiatGatewayForm.amount,
        feeObject: this.calculateFee,
      } )
    },
  },
  methods: {
    ...mapActions( "depositAndWithdrawal", [ "updateWithdrawFiatGatewayForm", "apiWithdrawFiatGateway", "resetDepositAndWithdrawalModule" ] ),
    handleInput( value, field ) {
      this.updateWithdrawFiatGatewayForm( {
        field,
        value,
      } )
    },
    handleSubmitWithdraw() {
      const { amount } = this.withdrawFiatGatewayForm
      this.updateWithdrawFiatGatewayForm( {
        field: 'currency',
        value: this.currency,
      } )
      this.validateForm().then( () => {
        if ( amount.length && amount > 0 ) {
          this.confirmStep = true
        } else {
          this.handleWithdrawConfirmed()
        }
      } )
    },
    handleClickInputBtn() {
      const availableAmountToWithdraw = withdrawAll( {
        currency: this.currency,
        type: 'GATEWAY',
      } )
      this.handleInput( availableAmountToWithdraw, 'amount' )
    },
    handleWithdrawConfirmed() {
      this.buttonDisableConfirm = true
      this.apiWithdrawFiatGateway()
        .then( () => {
          this.confirmStep = false
          if ( this.isEmailConfirmationRequired ) { 
            this.emailConfirmStep = true 
          } else {
            showAppNotification('success', 'Withdrawal successfully created!');
          }
        } )
        .catch( () => {
          setTimeout( () => {
            this.buttonDisableConfirm = false
          }, 2000 )
        } )
    },
    handleCancelConfirm() {
      this.confirmStep = false
    },
    handleCancelEmailConfirm() {
      // cancel email confirm after withdrawal was already created
      this.resetDepositAndWithdrawalModule()
      this.emailConfirmStep = false
      this.confirmStep = false
    },
    handleWithdrawConfirmedByEmail() {
      this.resetDepositAndWithdrawalModule()
      this.emailConfirmStep = false
    },
  },
}
</script>

<style lang='scss'
       scoped
>
@import "./WithdrawGateway.scss";

.TextBtn_root {
  font-size: 12px;
  line-height: 14px;
  color: #2af3f3;
  margin-top: 10px;
  cursor: pointer;
  display: flex;
  text-align: left;

  svg {
    margin-right: 2px;
    vertical-align: middle;
  }
}
</style>
