<template>
  <div
    v-if="false"
    class="md:min-w-[205px] relative"
    v-click-outside="closeDropDownTemplate"
  >
    <div
      class="cursor-pointer p-[5px] flex justify-center items-center font-[500] tracking-[4%] w-full rounded-tl-[8px] rounded-tr-[8px] rounded-br-[8px] rounded-bl-[8px] min-h-[32px] bg-gray-600/[0.06] text-[12px] leading-[14px] text-blue-500"
      :class="{ 'md:rounded-br-[0] md:rounded-bl-[0]': visible }"
      @click="toggleVisible"
    >
      <div
        class="flex items-center toggle-btn-text pr-[20px]"
        :class="{ 'toggle-btn-text-active': visible }"
      >
        <div class="mr-[5px]">
          <img src="@/assets/icons/icon-choose-template.svg">
        </div>
        <div class="hidden md:block">Choose template</div>
      </div>
    </div>
    <div
      class="z-[999] shadow-providers-history-row-root dropdown-filter min-w-[186px] md:w-full absolute top-[32px] right-0 md:right-auto md:left-0 rounded-bl-[6px] rounded-br-[6px] shadow-component-wrapper-root bg-blue-800 pt-[12px] pb-[12px] pl-[12px] pr-[12px]"
      :class="{ 'open': visible }"
    >
      <div class="relative mb-[10px] flex">
        <img src="@/assets/icons/icon-search.svg" class="absolute left-0 bottom-[5px]">
        <input
          type="text"
          v-model="searchTemplate"
          placeholder="Search"
          class="border-b-[1px] border-[#a6aebf]/[0.21] text-[10px] font-normal leading-[12px] text-[#A6AEBF] pl-[15px] pr-[15px] pt-[2px] pb-[2px] text-white w-full h-[20px]"
        >
      </div>
      <div
        class="cursor-pointer flex items-center text-[12px] leading-[14px] font-normal text-white hover:text-blue-500 transition mb-[10px] last:mb-0"
        v-for="template in templates"
        :key="template.id"
        @click="onChooseTemplate(template.name)"
      >
        <img
          :src="getImg(template.iconUrl)"
          class="mr-[5px]"
        >
        {{ template.title }}
      </div>
      <button class="flex items-center justify-center w-full bg-blue-500/[0.1] hover:bg-blue-500/[0.2] transition rounded-[6px] min-h-[24px] p-[5px]">
        <div class="flex flex-wrap items-center">
          <img src="@/assets/icons/icon-add-template.svg" class="mr-[5px]">
          <span class="text-[10px] text-blue-500 tracking-[1.1px]">New template</span>
        </div>
      </button>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ChooseTemplate',
  data() {
    return {
      visible: false,
      searchTemplate: '',
      templates: [
        {
          title: 'Template 01/02.04.22',
          iconUrl: 'icon-template.svg',
          id: 1
        },
        {
          title: 'Template 02/18.04.22',
          iconUrl: 'icon-template.svg',
          id: 2
        },
      ],
    };
  },
  methods: {
    getImg(url) {
      try {
        return require(`@/assets/icons/${ url }`);
      } catch (error) {
        return '';
      }
    },
    onChooseTemplate( nameTemplate ) {
      this.$emit('onChooseWalletItemsFilter', nameTemplate)
      this.visible = false
    },
    toggleVisible() {
      this.visible = !this.visible
    },
    closeDropDownTemplate() {
      this.visible = false
    }
  },
};
</script>
<style scoped lang="scss">
.toggle-btn-text {
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%) rotate(0deg);
    right: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 4px 4px 0 4px;
    border-color: #2af3f3 transparent transparent transparent;
  }

  &.toggle-btn-text-active {
    &:after {
      transform: translateY(-50%) rotate(180deg);
    }
  }
}
.dropdown-filter {
  opacity: 0;
  visibility: hidden;

  &.open {
    opacity: 1;
    visibility: visible;
  }
}
</style>