<template>
  <div class="WithdrawCrypto_root">
    <div class="WithdrawCrypto_wrapper">
      <div class="WithdrawCrypto_container">
        <div class="WithdrawCrypto_form" v-if="isConfirmationStepsActive">
          <v-form ref="form" autocomplete="false">
            <!-- TODO: need fix withdrawal rules api obj -->
            <div class="WithdrawCrypto_row" v-if="networksList.length && checkDisableDescriptionWithdrawal !== 'BLOCKED'">
              <Select
                :value="withdrawCryptoForm.payment_method || 'Please choose network'"
                :items='networksList'
                name='payment_method'
                label='Network'
                :rules="[() => withdrawCryptoForm.payment_method != 'Please choose network']"
                @change='handleChangePaymentNetworkType'
              />
            </div>
            <PassVerification operation='withdrawal' v-if='checkDisableDescriptionWithdrawal === "NOT_VERIFIED"' />
            <WithdrawBlocked v-else-if='checkDisableDescriptionWithdrawal === "BLOCKED"' />
            <template v-else-if="selectedPaymentType.value !== 'Please choose network'">
              <div class='WithdrawCrypto_row'>
                <TextFieldInput
                  :value="withdrawCryptoForm.wallet_to"
                  name="wallet_to"
                  label="Address"
                  :large="true"
                  hideDetails="auto"
                  :rules='[...inputRules.required, ...validateField]'
                  @input="handleInput(arguments[0], 'wallet_to')"
                />
              </div>
              <div class='WithdrawCrypto_row'>
                <div class="flex items-end">
                  <TextFieldInput
                    ref="textFieldInput"
                    :value="withdrawCryptoForm.amount"
                    name="amount"
                    label="You Get"
                    hideDetails="auto"
                    btnText="Withdraw all"
                    type="number"
                    withButton
                    :rules='[...validateMinLimitWithdrawalAll]'
                    @input="handleInput(arguments[0], 'amount')"
                    @clickBtn="handleClickInputBtn"
                    defaultBtn
                  />
                </div>
                <div class="TextBtn_root" @click="showComment = !showComment">
                  <iconComment class="TextBtn_icon" />
                  Add comments
                </div>
              </div>
              <transition name='fade'
                mode='out-in'
              >
                <div class='WithdrawCrypto_row'
                  v-if='showComment'
                >
                  <TextArea
                    :value="withdrawCryptoForm.comment"
                    name="comment"
                    label="Comment"
                    hideDetails
                    @input="handleInput(arguments[0], 'comment')"
                  />
                </div>
              </transition>
              <OutlineButton 
                class="outline-button-default outline-button-full-width outline-button" 
                text="Withdraw" 
                currencyType 
                :currency="currency" 
                fullWidth 
                @click="handleSubmitWithdraw" 
              />
            </template>
          </v-form>
        </div>
        <ConfirmWithdraw
          v-if='confirmStep'
          :currency='currency'
          :buttonDisableConfirm="buttonDisableConfirm"
          :networkExists='isNetworksExist'
          :networkType='selectedPaymentType'
          @cancelWithdraw='handleCancelConfirm'
          @confirmWithdraw='handleWithdrawConfirmed'
        />
        <EmailConfirmWithdraw
          v-if="emailConfirmStep"
          v-model="emailCode"
          @cancelWithdraw="handleCancelEmailConfirm"
          @confirmWithdrawByEmail="handleWithdrawConfirmedByEmail"
          :buttonDisableConfirm="buttonDisableConfirm"
        />
        <G2FAConfirmWithdraw
          ref="g2fa"
          v-if="g2faConfirmStep"
          @cancelWithdraw="handleCancelG2FAConfirm"
          @confirmWithdrawByG2FA="handleWithdrawConfirmedByG2FA"
        />
        <!-- <div class="WithdrawCrypto_faq mt-[20px] md:mt-[45px] 2xl:mt-[85px]"> -->
        <div class="WithdrawCrypto_faq">
          <CryptoLimitFeePanel
            :network="selectedPaymentType ? selectedPaymentType.value : ''"
            operation='withdrawal'
            :currency='currency'
            :fees='prepareFeeInfo'
            :limits='prepareLimitsInfo'
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import inputRules from "@/utils/inputRules";
import TextFieldInput from "@/components/_common/FormElements/TextFieldInput/TextFieldInput";
import TextArea from "@/components/_common/FormElements/TextArea/TextArea";
import OutlineButton from "@/components/_common/FormElements/OutlineButton/OutlineButton";
import CryptoLimitFeePanel from "@/components/_common/LimitFeePanel/CryptoLimitFeePanel";
import withdrawAll from "@/components/_common/Wallet/_helpers/withdrawAll";
import calculateOperationFee from "@/components/_common/Wallet/_helpers/calculateOperationFee";
import { getLimitsByCurrency } from "@/store/exchange/_helpers/exchangeLimits";
import { iconComment } from "@/assets/icons/common";
import { validateForm } from "@/mixins/validateForm";
import { walletDepositAndWithdraw } from "@/mixins/walletDepositAndWithdraw";
import ConfirmWithdraw from "../ConfirmWithdraw";
import EmailConfirmWithdraw from "../EmailConfirmWithdraw";
import G2FAConfirmWithdraw from "../G2FAConfirmWithdraw";
import Select from "@/components/_common/FormElements/Select/Select";
import { showAppNotification } from "@/utils/appNotification";
import WithdrawBlocked from "@/components/_common/WithdrawBlocked"
import PassVerification from "@/components/_common/PassVerification/PassVerification"

export default {
  props: {
    currency: {
      type: String,
      required: true,
      default: 'BTC',
    },
    methodName: {
      type: String,
      default: '',
    },
  },
  mixins: [ validateForm, walletDepositAndWithdraw ],
  components: {
    WithdrawBlocked,
    OutlineButton,
    CryptoLimitFeePanel,
    TextFieldInput,
    TextArea,
    iconComment,
    ConfirmWithdraw,
    EmailConfirmWithdraw,
    G2FAConfirmWithdraw,
    Select,
    PassVerification,
  },
  data: () => ( {
    inputRules,
    showComment: false,
    confirmStep: false,
    emailConfirmStep: false,
    g2faConfirmStep: false,
    selectedPaymentType: '',
    emailCode: null,
    totpCode: null,
    buttonDisableConfirm: false,
  }),
  computed: {
    ...mapState('user', ['userApiSettings', 'currenciesVerifyWithdrawList', 'userWithdrawalRules', 'userTotpEnabled']),
    ...mapState('depositAndWithdrawal', ['withdrawCryptoForm']),
    ...mapGetters( 'user', [ 'isVerified', 'getStatusDisableDescriptionWithdrawal' ] ),
    validateField() {
      if (this.currency === 'BTC') {
        return [this.inputRules?.getCheckValidBtcWallet[0]]
      }
      if (this.currency === 'ETH') {
        return [this.inputRules?.getCheckValidEthWallet[0]]
      }
      if (this.currency === 'USDT') {
        if(this.selectedPaymentType.value === 'TRC20') {
          return [this.inputRules?.getCheckValidTroneWallet[0]]
        }
        return [this.inputRules?.getCheckValidUsdtWallet[0]]
      }
      if (this.currency === 'LTC') {
        return [this.inputRules?.getCheckValidLtcWallet[0]]
      }
      if (this.currency === 'BNB') {
        return [this.inputRules?.getCheckValidBnbWallet[0]]
      }
      if (this.currency === 'ADA') {
        return [this.inputRules?.getCheckValidAdaWallet[0]]
      }
      if (this.currency === 'DOGE') {
        return [this.inputRules?.getCheckValidDogeWallet[0]]
      }
      if (this.currency === 'TRON') {
        return [this.inputRules?.getCheckValidTroneWallet[0]]
      }
    },
    checkDisableDescriptionWithdrawal() {
      if (this.isNetworksExist && this.selectedPaymentType && this.selectedPaymentType.value !== 'Please choose network') {
        return this.getStatusDisableDescriptionWithdrawal( this.currency, this.methodName, this.selectedPaymentType.value )
      } else {
        return this.getStatusDisableDescriptionWithdrawal( this.currency, this.methodName )
      }
    },
    isConfirmationStepsActive() {
      return !this.confirmStep && !this.emailConfirmStep && !this.g2faConfirmStep;
    },
    isEmailConfirmationRequired() {
      const { IS_WITHDRAWAL_EMAIL_VERIFICATION_ENABLED } = this.userApiSettings
      const currencyNeedsToBeVerified = this.currenciesVerifyWithdrawList.includes( this.currency )
      return IS_WITHDRAWAL_EMAIL_VERIFICATION_ENABLED && currencyNeedsToBeVerified
    },
    prepareLimitsInfo() {
      const limitsObject = getLimitsByCurrency( {
        currency: this.currency,
        type: 'GATEWAY',
        operation: 'withdrawal',
      } )
      if ( !this.isNetworksExist ) {
        return limitsObject
      }
      // console.log(limitsObject)
      const { value: selectedNetwork } = this.selectedPaymentType
      return limitsObject[ selectedNetwork ]
    },
    prepareFeeInfo() {
      const feeObject = calculateOperationFee( {
        currency: this.currency,
        type: 'GATEWAY',
        operation: 'withdrawal',
      } )
      if ( !this.isNetworksExist ) {
        return feeObject
      }
      const { value: selectedNetwork } = this.selectedPaymentType
      return feeObject[ selectedNetwork ]
    },
    computedFee() {
      let feeObject = calculateOperationFee( {
        currency: this.currency,
        type: 'GATEWAY',
        operation: 'withdrawal',
      } )
      if ( this.isNetworksExist ) {
        const { value: selectedNetwork } = this.selectedPaymentType
        feeObject = feeObject[ selectedNetwork ]
      }
      return this.computedFeeHelper( feeObject )
    },
    isNetworksExist() {
      const withdrawalRules = Object.keys( this.userWithdrawalRules[ this.currency ].GATEWAY )
      // TODO: Get from BE list of Networks return
      return withdrawalRules.some( i => [ 'ERC20', 'TRC20', 'BEP20' ].includes( i ) )
    },
    networksList() {
      const processingRules = this.userWithdrawalRules[this.currency].GATEWAY
      if (this.isNetworksExist) {
        let keys = Object.keys(processingRules);
        keys.push(keys.shift());
        return keys.reduce(
          (acc, network) => {
            processingRules[network]?.is_withdrawal_enabled
              ? acc.push({ key: network, value: network })
              : processingRules[network]?.disable_description !== 'DISABLED'
              ? acc.push({ key: network, value: network })
              : acc;

            return acc;
          },
          [{ key: "Please choose network", value: "Please choose network", disabled: true }],
          // TODO: need to fix
        ).filter(item=>[ "ERC20", "TRC20", "BEP20", "Please choose network" ].includes(item.key))
      }
      return []
    },
    validateMinLimitWithdrawalAll() {
      const textFieldError = this.withdrawCryptoForm.amount === "" ? "This field is required" : "Your entered value is smaller than minimum withdraw value"
      if(this.prepareLimitsInfo?.min_amount) {
        return [ v => Number(v) >= Number(this.prepareLimitsInfo?.min_amount) || textFieldError]
      }
    }
  },
  methods: {
    ...mapActions('depositAndWithdrawal', [
      'updateWithdrawCryptoForm',
      'apiWithdrawCrypto',
      'resetDepositAndWithdrawalModule',
      'apiVerifyWithdrawByEmail',
    ]),
    handleInput( value, field ) {
      this.updateWithdrawCryptoForm( {
        field,
        value,
      } )
    },
    handleSubmitWithdraw() {
      this.updateWithdrawCryptoForm( {
        field: 'currency',
        value: this.currency,
      } )
      this.updateWithdrawCryptoForm( { field: 'payment_method', value: this.selectedPaymentType.value } )
      this.validateForm().then( () => {
        this.confirmStep = true
      } )
    },
    handleClickInputBtn() {
      const availableAmountToWithdraw = withdrawAll( {
        currency: this.currency,
        type: 'GATEWAY',
        network: this.isNetworksExist ? this.selectedPaymentType.value : '',
      } )
      this.handleInput( availableAmountToWithdraw, 'amount' )
      this.$refs.textFieldInput.$children[0].$refs.input.focus()
    },
    handleWithdrawConfirmed() {
      if (this.userTotpEnabled && !this.isEmailConfirmationRequired) {
        this.g2faConfirmStep = true;
        this.confirmStep = false;
      } else {
        this.buttonDisableConfirm = true;
        this.apiWithdrawCrypto().then(() => {
          if (this.isEmailConfirmationRequired) {
            this.emailConfirmStep = true;
          } else {
            showAppNotification('success', 'Withdrawal successfully created!');
          }
          this.confirmStep = false;
          this.buttonDisableConfirm = false;
        }).catch( () => {
          setTimeout( () => {
            this.buttonDisableConfirm = false;
          }, 2000 );
        } );
      };
    },
    handleCancelConfirm() {
      this.confirmStep = false
    },
    handleCancelEmailConfirm() {
      this.resetDepositAndWithdrawalModule();
      this.emailConfirmStep = false;
      this.confirmStep = false;
    },
    handleCancelG2FAConfirm() {
      this.resetDepositAndWithdrawalModule();
      this.g2faConfirmStep = false;
      this.emailConfirmStep = false;
      this.confirmStep = false;
    },
    handleWithdrawConfirmedByEmail() {
      this.emailConfirmStep = false;
      if (this.userTotpEnabled) {
        this.g2faConfirmStep = true;
      } else {
        this.apiVerifyCodes();
      }
    },
    handleWithdrawConfirmedByG2FA(totpCode) {
      this.totpCode = totpCode;
      if (this.isEmailConfirmationRequired) {
        this.apiVerifyCodes();
      } else {
        this.updateWithdrawCryptoForm({ field: 'totp_code', value: totpCode });
        this.totpCode = null;
        this.$refs.g2fa.$refs.g2faConfirm.clearAuthCode()
        this.apiWithdrawCrypto().then(() => {
          this.resetDepositAndWithdrawalModule();
          this.g2faConfirmStep = false;
          showAppNotification('success', 'Withdrawal successfully created!');
        }).catch((error)=>{
        });
      }
    },
    apiVerifyCodes() {
      const request = {};
      if (this.emailCode) request.email_code = this.emailCode;
      if (this.totpCode) request.totp_code = this.totpCode;
      this.buttonDisableConfirm = true;
      this.apiVerifyWithdrawByEmail(request).then(() => {
        this.resetDepositAndWithdrawalModule();
        this.g2faConfirmStep = false;
        this.emailConfirmStep = false;
        this.emailCode = null;
        this.totpCode = null;
        showAppNotification('success', 'Withdrawal successfully created!');
        this.buttonDisableConfirm = false;
      }).catch((error)=>{
        this.$refs.g2fa.$refs.g2faConfirm.clearAuthCode()
        setTimeout( () => {
          this.buttonDisableConfirm = false;
        }, 2000 );
      });
    },
    checkIfNetworkExists() {
      if ( this.isNetworksExist ) {
        this.selectedPaymentType = this.networksList[ 0 ]
      }
    },
    handleChangePaymentNetworkType( object ) {
      this.selectedPaymentType = object
      this.updateWithdrawCryptoForm( { field: "payment_method", value: object.value } );
    }
  },
  mounted() {
    this.checkIfNetworkExists()
  },
}
</script>

<style lang='scss'>
@import "./WithdrawCrypto.scss";
</style>
