<template>
  <div class="WalletMethod_root">
    <div class="WalletMethod_wrapper">
      <div class="md:flex md:justify-between md:items-start">
        <div
          v-if='checkDisableDescriptionWithdrawal === "NOT_VERIFIED"'
          class="grow"
        >
          <PassVerification operation="withdrawal" />
        </div>
        <WithdrawBlocked v-else-if='checkDisableDescriptionWithdrawal === "BLOCKED"' />
        <div
          v-else
          class="grow rounded-[12px] bg-blue-800/[0.44] shadow-component-wrapper-root py-[15px] px-[15px] md:py-[30px] md:px-[40px]"
        >
          <v-form
            ref="form"
            autocomplete="false"
          >
            <WalletMethodTitle>
              <div class="md:text-center md:mb-[10px] lg:mb-0 lg:text-left lg:text-[16px] 2xl:text-[21px]">Beneficiary Bank</div>
              <ChooseTemplate/>
            </WalletMethodTitle>
            <WalletMethodSeparator class="!mb-[20px]" />
            <WalletMethodRow class="input-row">
              <TextFieldInput
                :value="withdrawForm.additional_info.bank_details.bic"
                name="bank_bic"
                label="swift / bic *"
                hideDetails="auto"
                :rules="[...inputRules.required, ...inputRules.getCheckSpecialSymbolSwiftBic]"
                @input="handleInput(arguments[0], 'additional_info.bank_details.bic')"
                removeSpacesHandler
              />
              <TextFieldInput
                :value="withdrawForm.additional_info.bank_details.name"
                name="bank_name"
                label="name *"
                hideDetails="auto"
                @input="handleInput(arguments[0], 'additional_info.bank_details.name')"
                :maxLength="150"
                type="text"
              />
            </WalletMethodRow>
            <WalletMethodRow class="input-row">
              <TextFieldInput
                :value="withdrawForm.additional_info.bank_details.address"
                name="bank_address"
                label="address *"
                hideDetails="auto"
                :rules="[...inputRules.required]"
                @input="handleInput(arguments[0], 'additional_info.bank_details.address')"
                :maxLength="150"
              />
              <TextFieldInput
                :value="withdrawForm.additional_info.bank_details.location"
                name="bank_location"
                label="location *"
                hideDetails="auto"
                :rules="[...inputRules.required, ...inputRules.getCheckOnlyLetters]"
                @input="handleInput(arguments[0], 'additional_info.bank_details.location')"
                :maxLength="150"
              />
            </WalletMethodRow>
            <WalletMethodRow class="input-row">
              <Select
                :items="countries"
                :value="withdrawForm.additional_info.bank_details.country"
                name="bank_country"
                label="country *"
                hideDetails="auto"
                :rules="[...inputRules.required, ...inputRules.getCheckSpecialSymbol]"
                @change="handleInput(arguments[0].value, 'additional_info.bank_details.country')"
                :maxLength="150"
                componentName="VAutocomplete"
              />
              <TextFieldInput
                :value="withdrawForm.additional_info.bank_details.aba"
                name="bank_aba"
                label="ABA/RTN"
                hideDetails="auto"
                @input="handleInput(arguments[0], 'additional_info.bank_details.aba')"
                :rules="[() => withdrawForm.additional_info.bank_details.aba.length <= 9 || 'Maximum 9 numbers']"
                type="number"
              />
            </WalletMethodRow>
            <OutlineButton
              class="WalletMethod_copyBtn w-full max-w-[530px]"
              text="Next step"
              currencyType
              :currency="currency"
              @click="handleSubmitWithdraw"
            />
          </v-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import inputRules from '@/utils/inputRules'
import TextFieldInput from '@/components/_common/FormElements/TextFieldInput/TextFieldInput'
import OutlineButton from '@/components/_common/FormElements/OutlineButton/OutlineButton'
import PassVerification from '@/components/_common/PassVerification/PassVerification'
import { validateForm } from '@/mixins/validateForm'
import { walletDepositAndWithdraw } from '@/mixins/walletDepositAndWithdraw'
import Countries from '@/utils/countries.js'
import Select from '@/components/_common/FormElements/Select/Select'
import WithdrawBlocked from '@/components/_common/WithdrawBlocked'
import WalletMethodRow from "@/components/WalletMethodRow"
import WalletMethodTitle from "@/components/WalletMethodTitle"
import WalletMethodSeparator from "@/components/WalletMethodSeparator"
import ChooseTemplate from "@/components/_common/ChooseTemplate/ChooseTemplate";

export default {
  name: "WithdrawSwiftSepaStepOne",
  props: {
    currency: {
      type: String,
      required: false
    },
      methodName: {
      type: String
    }
  },
  mixins: [validateForm, walletDepositAndWithdraw],
  components: {
    ChooseTemplate,
    WithdrawBlocked,
    WalletMethodSeparator,
    WalletMethodTitle,
    WalletMethodRow,
    OutlineButton,
    TextFieldInput,
    PassVerification,
    Select
  },
  data: function() {
    return {
      inputRules,
    }
  },
  computed: {
    ...mapState('depositAndWithdrawal', ['withdrawSwiftForm', 'withdrawSepaForm']),
    ...mapGetters('user', ['isVerified', 'getStatusDisableDescriptionWithdrawal']),
    ...mapState('user', ['userApiSettings', 'currenciesVerifyWithdrawList']),
    checkDisableDescriptionWithdrawal() {
      return this.getStatusDisableDescriptionWithdrawal(this.currency, this.methodName)
    },
    withdrawForm() {
      if (this.methodName == 'SWIFT') {
        return this.withdrawSwiftForm
      } else if (this.methodName == 'SEPA') {
        return this.withdrawSepaForm
      }
      return this.withdrawSepaForm
    },
    countries() {
      return Countries.map(item => item.country)
    },
  },
  methods: {
    ...mapActions('depositAndWithdrawal',
      [
        'updateWithdrawSwiftForm',
        'updateWithdrawSepaForm',
        'apiWithdrawSwiftSepa'
      ]),
    updateWithdrawForm({ value, field }) {
      if (this.methodName == 'SWIFT') {
        this.updateWithdrawSwiftForm({ value, field })
      } else if (this.methodName == 'SEPA') {
        this.updateWithdrawSepaForm({ value, field })
      }
    },
    handleInput(value, field) {
      this.updateWithdrawForm({ value, field })
    },
    handleSubmitWithdraw() {
      this.validateForm()
        .then(() => {
          this.updateWithdrawForm({ value: 2, field: 'step' })
        })
    },
  },
}
</script>
<style scoped lang="scss">
  .input-row {
    display: grid;
    grid-gap: 15px;
    margin: 0 0 15px;

    @media only screen and (min-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 20px;
    }
  }
</style>
