<template>
  <div class="WithdrawCrypto_form">
    <v-form ref="form" autocomplete="false">
      <div class="WithdrawCrypto_title">Security check</div>
      <div class="WithdrawCrypto_row !mb-[20px]">
        <TextFieldInput
          :value="emailCode"
          name="email_code"
          label="email code confirmation"
          :rules="[...inputRules.required]"
          hideDetails
          @input="handleInputCode(arguments[0])"
        />
        <div class="WithdrawCrypto_hint">Enter 6-digit code, sent to {{ userInfo.email }}</div>
      </div>
      <div class="WithdrawCrypto_row-btns">
        <OutlineButton
          class="outline-button-default outline-button-full-width"
          text="Submit"
          @click="handleSubmitWithdraw"
          :disabled="buttonDisableConfirm"
        />
        <Button text="cancel" size="large" @click="handleCancelExchange" />
      </div>
    </v-form>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { validateForm } from '@/mixins/validateForm';
import inputRules from '@/utils/inputRules';
import TextFieldInput from '@/components/_common/FormElements/TextFieldInput/TextFieldInput';
import OutlineButton from '@/components/_common/FormElements/OutlineButton/OutlineButton';
import Button from '@/components/_common/FormElements/Button/Button';

export default {
  components: {
    OutlineButton,
    TextFieldInput,
    Button,
  },
  mixins: [validateForm],
  props: {
    emailCode: {
      type: [String, Number],
      default: '',
    },
    buttonDisableConfirm: {
      type: Boolean,
      default: false,
    }
  },
  data: () => ({
    inputRules,
  }),
  computed: {
    ...mapState('user', ['userInfo', 'userTotpEnabled']),
  },
  methods: {
    ...mapActions('depositAndWithdrawal', ['apiVerifyWithdrawByEmail']),
    handleSubmitWithdraw() {
      this.validateForm().then(() => {
        this.$emit('confirmWithdrawByEmail');
      });
    },
    handleInputCode(value) {
      this.$emit('input', value);
    },
    handleCancelExchange() {
      this.$emit('cancelWithdraw');
    },
  },
};
</script>

<style lang="scss" scoped>
@import './WithdrawCrypto/WithdrawCrypto.scss';
.WithdrawCrypto_row-btns {
  display: flex;
  justify-content: space-between;
  align-items: center;
  button {
    flex: 0 1 48%;
  }
}
.WithdrawCrypto_title {
  font-weight: 500;
  font-size: 21px;
  line-height: 25px;
  text-align: left;
  text-transform: uppercase;
  color: #ffffff;
  padding-bottom: 12px;
  border-bottom: 1px solid rgba(#2af3f3, 0.1);
  margin-bottom: 12px;
}

.WithdrawCrypto_hint {
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  text-align: left;
  margin-top: 10px;
  margin-bottom: 20px;
}
</style>
