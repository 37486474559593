<template>
  <div class="WalletMethod_Confirm">
    <v-form ref="form" autocomplete="false">
      <div class="WalletMethod_title">Confirm withdrawal</div>
      <div class="WalletMethod_separator !mb-[20px]"></div>
      <div class='WalletMethod_row'>
        <TextFieldInput
          disabled
          :value='internalMovementForm.destination_account_email'
          name='destination_account_email'
          label='to'
          hideDetails
        />
      </div>
      <div class="WalletMethod_row">
        <TextFieldInput
          disabled
          :value='internalMovementForm.amount'
          name='amount'
          label='amount'
          hideDetails
        />
      </div>
      <div class="WalletMethod_row" v-if="internalMovementForm.comment">
        <TextArea
          disabled
          :value='internalMovementForm.comment'
          name='comment'
          label='Comment'
          hideDetails
        />
      </div>
      <div class="WalletMethod_row WalletMethod_row--btns">
        <OutlineButton 
          class="WalletMethod_Confirm_copyBtn" 
          text="Confirm" 
          fullWidth 
          @click="handleSubmitWithdraw" 
          :disabled="buttonDisableConfirm" 
        />
        <Button 
          text="cancel" 
          size="large" 
          @click="handleCancelExchange" 
        />
      </div>
    </v-form>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import OutlineButton from '@/components/_common/FormElements/OutlineButton/OutlineButton';
import Button from '@/components/_common/FormElements/Button/Button';
import TextFieldInput from '@/components/_common/FormElements/TextFieldInput/TextFieldInput'
import TextArea from '@/components/_common/FormElements/TextArea/TextArea'

export default {
  props: {
    currency: {
      type: String,
      required: true,
      default: 'EUR',
    },
    buttonDisableConfirm: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    OutlineButton,
    Button,
    TextFieldInput,
    TextArea,
  },
  data: () => ({}),
  computed: {
    ...mapState('depositAndWithdrawal', ['internalMovementForm']),
  },
  methods: {
    handleSubmitWithdraw() {
      this.$emit('confirmWithdraw');
    },
    handleCancelExchange() {
      this.$emit('cancelWithdraw');
    },
  },
};
</script>

<style lang="scss" scoped>
.WalletMethod_Confirm::v-deep {
    .theme--dark.v-input--is-disabled textarea {
      color: #fff;
    }
  }
</style>
