<template>
  <div class='WalletMethod_root'>
    <div class='WalletMethod_wrapper'>
      <div class='lg:flex lg:justify-between lg:items-start'>
        <div
          v-if='checkDisableDescriptionWithdrawal === "NOT_VERIFIED"'
          class='grow m-[0_0_20px] md:m-[0_30px_0_0]'
        >
          <PassVerification operation='withdrawal' />
        </div>
        <WithdrawBlocked v-else-if='checkDisableDescriptionWithdrawal === "BLOCKED"' />
        <div
          v-else
          class='grow m-[0_0_20px] md:m-[0_30px_0_0]'
        >
          <StepsBar
            :stepsBar="stepsBar"
            :activeStep="withdrawSepaFormVerified.step"
          ></StepsBar>
          <component
            :is="currentStepComponentVerified"
            :methodName="methodName"
            :currency="currency"
          ></component>
        </div>
        <!-- <div class="shirk-0 md:basis-[390px] mt-[20px] md:mt-[45px] 2xl:mt-[85px]"> -->
        <div class="shirk-0 md:basis-[390px]">
          <LimitFeePanel
            operation='withdrawal'
            :limits='prepareLimitsInfo'
            :fees='prepareFeeInfo'
            :currency='currency'
            :method='methodName'
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StepsBar from "@/components/_common/StepsBar";
import { mapState, mapGetters, mapActions } from 'vuex'
import LimitFeePanel from '@/components/_common/LimitFeePanel/LimitFeePanel'
import PassVerification from '@/components/_common/PassVerification/PassVerification'
import calculateOperationFee from '@/components/_common/Wallet/_helpers/calculateOperationFee'
import { getLimitsByCurrency } from '@/store/exchange/_helpers/exchangeLimits'
import { validateForm } from '@/mixins/validateForm'
import Input from '@/components/_common/FormElements/Input/Input'
import { walletDepositAndWithdraw } from '@/mixins/walletDepositAndWithdraw'
import Select from '@/components/_common/FormElements/Select/Select'
import WithdrawBlocked from '@/components/_common/WithdrawBlocked'
import WalletMethodTitle from "@/components/WalletMethodTitle"
import ChooseTemplate from "@/components/_common/ChooseTemplate/ChooseTemplate";
import WithdrawSepaStepOneVerified from "@/views/Application/Account/Exchange/Wallet/DepositAndWithdraw/Withdraw/WithdrawSepaVerified/WithdrawSepaStepOneVerified";
import WithdrawSepaStepConfirmVerified from "@/views/Application/Account/Exchange/Wallet/DepositAndWithdraw/Withdraw/WithdrawSepaVerified/WithdrawSepaStepConfirmVerified";
export default {
  props: {
    currency: {
      type: String,
      required: true
    },
    methodName: {
      type: String
    }
  },
  mixins: [ validateForm, walletDepositAndWithdraw ],
  components: {
    StepsBar,
    WithdrawSepaStepOneVerified,
    WithdrawSepaStepConfirmVerified,
    ChooseTemplate,
    WithdrawBlocked,
    WalletMethodTitle,
    LimitFeePanel,
    PassVerification,
    Input,
    Select
  },
  data() {
    return {
      stepsBar: [
        {
          stepNumber: 1,
          stepName: 'Personal information',
          iconUrl: 'icon-personal-info-step.svg'
        },
        {
          stepNumber: 2,
          stepName: 'Confirm',
          iconUrl: 'icon-confirm-step.svg'
        }
      ]
    }
  },
  computed: {
    ...mapState( 'depositAndWithdrawal', [ 'withdrawSepaFormVerified' ] ),
    ...mapGetters( 'user', [ 'isVerified', 'getStatusDisableDescriptionWithdrawal' ] ),
    currentStepComponentVerified() {
      switch (this.withdrawSepaFormVerified.step) {
        case 1:
          return 'WithdrawSepaStepOneVerified';
        case 2:
          return 'WithdrawSepaStepConfirmVerified';
        default:
          return 1;
      }
    },
    checkDisableDescriptionWithdrawal() {
      return this.getStatusDisableDescriptionWithdrawal( this.currency, this.methodName )
    },
    prepareLimitsInfo() {
      const limitsObject = getLimitsByCurrency( {
        currency: this.currency,
        type: this.methodName,
        operation: "withdrawal",
      } )
      return limitsObject
    },
    prepareFeeInfo() {
      const feeObject = calculateOperationFee( {
        currency: this.currency,
        type: this.methodName,
        operation: "withdrawal",
      } )
      return feeObject
    },
  },
}
</script>
