<template>
  <div class="WalletMethod_root">
    <div class="WalletMethod_wrapper">
      <div class="md:flex md:justify-between md:items-start">
        <div
          v-if='checkDisableDescriptionWithdrawal === "NOT_VERIFIED"'
          class="grow"
        >
          <PassVerification operation="withdrawal" />
        </div>
        <WithdrawBlocked v-else-if='checkDisableDescriptionWithdrawal === "BLOCKED"' />
        <div
          v-else
          class="grow rounded-[12px] bg-blue-800/[0.44] shadow-component-wrapper-root py-[15px] px-[15px] md:py-[30px] md:px-[40px]"
        >
          <v-form
            ref="form"
            autocomplete="false"
          >
            <WalletMethodTitle>
              <div class="md:text-center md:mb-[10px] lg:mb-0 lg:text-left lg:text-[16px] 2xl:text-[21px]">Personal information</div>
            </WalletMethodTitle>
            <WalletMethodSeparator class="!mb-[20px]" />
            <WalletMethodRow>
              <TextFieldInput
                :value="withdrawSepaFormVerified.additional_info.customer_details.name"
                name="name"
                label="Name"
                @input="handleInput(arguments[0], 'additional_info.customer_details.name')"
                hideDetails="auto"
                :disabled="fullNameDisabled"
              />
            </WalletMethodRow>
            <WalletMethodRow>
              <TextFieldInput
                :value="withdrawSepaFormVerified.additional_info.customer_details.iban"
                name="iban"
                label="Iban"
                :rules="[...inputRules.required, ...inputRules.ibanRulesPackage ]"
                @input="handleInput(arguments[0], 'additional_info.customer_details.iban')"
                :maxLength="150"
                type="text"
                hideDetails="auto"
                removeSpacesHandler
              />
            </WalletMethodRow>
            <WalletMethodRow>
              <Input
                name='amount'
                label='You Get *'
                :value="withdrawSepaFormVerified.amount"
                btnText='Withdraw all'
                rightLabelString='FEE'
                :rightLabelValue="computedFee"
                :rules='[...inputRules.required, ...inputRules.getCheckSpecialSymbol]'
                type='number'
                @input="handleInput(arguments[0], 'amount')"
                @clickBtn='handleClickInputBtn'
                :maxLength='150'
                defaultBtn
              />
            </WalletMethodRow>
            <OutlineButton
              class="WalletMethod_copyBtn w-full max-w-[530px]"
              text="Next step"
              currencyType
              :currency="currency"
              @click="handleSubmitWithdrawVerified"
            />
          </v-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import calculateOperationFee from '@/components/_common/Wallet/_helpers/calculateOperationFee'
import withdrawAll from '@/components/_common/Wallet/_helpers/withdrawAll'
import inputRules from '@/utils/inputRules'
import TextFieldInput from '@/components/_common/FormElements/TextFieldInput/TextFieldInput'
import OutlineButton from '@/components/_common/FormElements/OutlineButton/OutlineButton'
import PassVerification from '@/components/_common/PassVerification/PassVerification'
import { validateForm } from '@/mixins/validateForm'
import { walletDepositAndWithdraw } from '@/mixins/walletDepositAndWithdraw'
import WithdrawBlocked from '@/components/_common/WithdrawBlocked'
import WalletMethodTitle from "@/components/WalletMethodTitle"
import WalletMethodSeparator from "@/components/WalletMethodSeparator"
import Input from '@/components/_common/FormElements/Input/Input'
import WalletMethodRow from "@/components/WalletMethodRow";

export default {
  name: "WithdrawSepaStepOneVerified",
  props: {
    currency: {
      type: String,
      required: false
    },
      methodName: {
      type: String
    }
  },
  mixins: [validateForm, walletDepositAndWithdraw],
  components: {
    WithdrawBlocked,
    WalletMethodSeparator,
    WalletMethodTitle,
    OutlineButton,
    TextFieldInput,
    PassVerification,
    Input,
    WalletMethodRow
  },
  data() {
    return {
      inputRules,
      fullNameDisabled: false,
    }
  },
  computed: {
    ...mapState('depositAndWithdrawal', ['withdrawSwiftForm', 'withdrawSepaFormVerified']),
    ...mapState('user', ['userApiSettings', 'currenciesVerifyWithdrawList']),
    ...mapGetters('user', ['getStatusDisableDescriptionWithdrawal', 'getUserInfoFullName']),
    checkDisableDescriptionWithdrawal() {
      return this.getStatusDisableDescriptionWithdrawal(this.currency, this.methodName)
    },
    computedFee() {
      const feeObject = calculateOperationFee( {
        currency: this.currency,
        type: this.methodName,
        operation: 'withdrawal',
      } )
      return this.computedFeeHelper( feeObject )
    },
  },
  methods: {
    ...mapActions('depositAndWithdrawal',
      [
        'updateWithdrawSwiftForm',
        'updateWithdrawSepaFormVerified',
        'apiWithdrawSwiftSepa'
      ]),
    handleInput(value, field) {
      this.updateWithdrawSepaFormVerified({ value, field })
    },
    handleSubmitWithdrawVerified() {
      this.validateForm()
        .then(() => {
          this.updateWithdrawSepaFormVerified({ value: 2, field: 'step' })
        })
    },
    handleClickInputBtn() {
      const availableAmountToWithdraw = withdrawAll( {
        currency: this.currency,
        type: this.methodName,
      } )
      this.handleInput( availableAmountToWithdraw, 'amount' )
    },
  },
  mounted() {
    if (this.getUserInfoFullName) {
      this.$nextTick(() => this.updateWithdrawSepaFormVerified({ value: this.getUserInfoFullName, field: 'additional_info.customer_details.name' }))
      this.fullNameDisabled = true
    }
  }
}
</script>
<style scoped lang="scss">
  .input-row {
    display: grid;
    grid-gap: 15px;
    margin: 0 0 15px;

    @media only screen and (min-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 20px;
    }
  }
</style>
