import store from '@/store';
import { getBalanceByCurrency } from '@/components/_common/Wallet/_helpers/userBalances';
const USER_LIMITS = store.state.user.userLimits.withdrawal;
const USER_FEES = store.state.user.userFees.withdrawal;

const withdrawAll = ({ currency, type, network }) => {
  const availableAmount = getBalanceByCurrency(currency);
  var minimumAmountToWithdraw;
  var currencyFeeObject;
  if (network) {
    minimumAmountToWithdraw =
      USER_LIMITS[currency] && USER_LIMITS[currency][type][network] && USER_LIMITS[currency][type][network].min_amount;
    currencyFeeObject = USER_FEES[currency] && USER_FEES[currency][type][network];
  } else {
    minimumAmountToWithdraw = USER_LIMITS[currency] && USER_LIMITS[currency][type] && USER_LIMITS[currency][type].min_amount;
    currencyFeeObject = USER_FEES[currency] && USER_FEES[currency][type];
  }

  const currencyPercentFee = currencyFeeObject.percent_fee || 0;
  const currencyStaticFee = currencyFeeObject.static_fee || 0;

  const subtractedAmountByPercent = Number(availableAmount) - Number(availableAmount) * (currencyPercentFee / 100);
  const subtractedAmountByPercentAndStatic = subtractedAmountByPercent - currencyStaticFee;
  const isAmountEnoughForWithdraw = subtractedAmountByPercentAndStatic > minimumAmountToWithdraw;
  if (isAmountEnoughForWithdraw) {
    return subtractedAmountByPercentAndStatic;
  }
  return 0;
};

export default withdrawAll;
