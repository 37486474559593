<template>
  <div class="WalletMethod_Confirm">
    <v-form
      ref="form"
      autocomplete="false"
    >
      <WalletMethodTitle>
        <div class="md:text-center md:mb-[10px] lg:mb-0 lg:text-left lg:text-[16px] 2xl:text-[21px]">Confirm withdrawal</div>
      </WalletMethodTitle>
      <div class="WalletMethod_separator !mb-[20px]"></div>
      <div class="flex flex-wrap items-end text-[16px] tracking-[0.4px] leading-[18px] mb-[13px]">
        <img src="@/assets/icons/icon-beneficiary.svg" class="mr-[5px]">
        <div>Beneficiary Bank</div>
      </div>
      <div class="md:flex md:flex-wrap">
        <div class="md:max-w-[50%] md:w-full">
          <div
            v-if="withdrawSepaFormVerified.additional_info.customer_details.name"
            class="flex items-center flex-wrap mb-[10px] justify-between md:justify-start"
          >
            <div class="text-[14px] leading-[16px] text-gray-600 max-w-[67px] w-full text-left mr-[24px]">Name:</div>
            <div class="text-white text-[14px] leading-[16px] font-normal text-right">{{ withdrawSepaFormVerified.additional_info.customer_details.name }}</div>
          </div>
          <div
            v-if="withdrawSepaFormVerified.additional_info.customer_details.iban"
            class="flex items-center flex-wrap mb-[10px] justify-between md:justify-start"
          >
            <div class="text-[14px] leading-[16px] text-gray-600 max-w-[67px] w-full text-left mr-[24px]">IBAN:</div>
            <div class="text-white text-[14px] leading-[16px] font-normal text-right">{{ withdrawSepaFormVerified.additional_info.customer_details.iban }}</div>
          </div>
        </div>
      </div>
      <div class="md:flex md:flex-wrap">
        <div class="md:max-w-[48%] md:w-full">
          <div
            v-if="withdrawSepaFormVerified.amount"
            class="flex items-center flex-wrap mb-[10px] justify-between md:justify-start"
          >
            <div class="text-[14px] leading-[16px] text-gray-600 mr-[24px] max-w-[90px] w-full text-left">You get:</div>
            <div class="text-white text-[14px] leading-[16px] font-normal">{{ withdrawSepaFormVerified.amount }} {{ currency }}</div>
          </div>
        </div>
      </div>
      <div class="flex items-center flex-wrap justify-center mt-[15px]">
        <Button
          class="mb-[10px] max-w-full w-full 2xl:max-w-[260px] 2xl:w-full 2xl:mr-[10px] 2xl:mb-[0]"
          text="Previous step"
          size="large"
          @click="handleCancel"
        />
        <OutlineButton
          class="2xl:max-w-[260px]"
          text="Confirm"
          fullWidth
          @click="handleWithdrawConfirmedVerification"
        />
      </div>
    </v-form>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import OutlineButton from '@/components/_common/FormElements/OutlineButton/OutlineButton';
import Button from '@/components/_common/FormElements/Button/Button';
import WalletMethodTitle from "@/components/WalletMethodTitle"
import { showAppNotification } from "@/utils/appNotification";

export default {
  name: 'WithdrawSepaStepConfirmVerified',
  components: {
    OutlineButton,
    Button,
    WalletMethodTitle
  },
  props: {
    currency: {
      type: String,
      required: true,
    },
    methodName: {
      type: String,
    },
  },
  computed: {
    ...mapState('depositAndWithdrawal', ['withdrawSepaFormVerified']),
  },
  methods: {
    ...mapActions( 'depositAndWithdrawal', ['updateWithdrawSepaFormVerified', 'apiWithdrawSepaVerified'] ),
    updateWithdrawFormVerification( { value, field } ) {
      this.updateWithdrawSepaFormVerified( { value, field } )
    },
    handleCancel() {
      this.updateWithdrawFormVerification({ value: 1, field: 'step' })
    },
    handleWithdrawConfirmedVerification() {
      this.updateWithdrawFormVerification( {
        field: 'currency',
        value: this.currency,
      } )
      this.apiWithdrawSepaVerified().then( () => {
        showAppNotification('success', 'Withdrawal successfully created!');
      } )
    },
  },
};
</script>
